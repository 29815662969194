import i18next from "i18next";
import { IossComplianceSubscription, VatComplianceSubscription } from "WebServices/APIs/self/models/V1/CompanyInfo/Subscriptions";
export const EXPIRED_SUBSCRIPTION_TYPE_LABELS = {
  IossCompliance: i18next.t("Ioss Compliance"),
  VatCompliance: i18next.t("Vat Compliance"),
  Unknown: i18next.t("Unknown")
};
export const REASON_DATA_SOURCE = [
  { reason: "Moving", label: i18next.t("I plan to move to a new service provider") },
  { reason: "Deregistration", label: i18next.t("I plan to stop trading and de-register for VAT") }
];
export const MOVING_REASON_DATA_SOURCE = [
  { movingReason: "Pricing", label: i18next.t("Pricing") },
  { movingReason: "ServiceQuality", label: i18next.t("Service quality") },
  { movingReason: "ScopeCoverage", label: i18next.t("Scope coverage") },
  { movingReason: "Other", label: i18next.t("Other") }
];
export const mapSubscriptionToExpiredSubscriptionVM = (subscription) => {
  const vm = {
    id: subscription.Id,
    countryCode: subscription instanceof VatComplianceSubscription ? subscription.CountryCode : void 0,
    type: subscription instanceof VatComplianceSubscription ? "VatCompliance" : subscription instanceof IossComplianceSubscription ? "IossCompliance" : "Unknown",
    startDate: subscription.StartDate,
    endDate: subscription.EndDate,
    movingReasons: [],
    opened: false
  };
  return vm;
};
