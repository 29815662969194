import "./contact-account-manager.scss";
import i18next from "i18next";
import { html } from "lit-html";
import { getRouteByKey } from "../../globals";
import { unsafeHTML } from "lit-html/directives/unsafe-html";
import { footerLinksTemplate, footerTextTemplate, headerTemplate } from "./../../../shared/templates/commons";
export const observedAttributes = ["redirect"];
export const name = "ww-contact-am";
export const Component = (host) => {
  const props = {
    redirect: host.redirect
  };
  return html`<div class="min-h-screen m-auto relative">
		${props.redirect ? headerTemplate() : ""}

		<div class="bg-white bg-opacity-75 text-black rounded px-8 py-4 m-auto flex flex-col mt-10">
			${props.redirect ? html`<div class="text-center">
						${unsafeHTML(
    i18next.t("ContactAccountManager", `Please contact us {{- link_first_part}} here {{- link_second_part}} to discuss how to sign up with us.`, {
      link_first_part: `<a data-routing="server" class="underline font-semibold" href="${getRouteByKey("contact")}">`,
      link_second_part: "</a>"
    })
  )}
				  </div>` : html`<div class="text-center">${i18next.t("Please contact your account manager via email to manage your services.")}</div>`}
		</div>
		${props.redirect ? html`<div class="w-full mt-4 sm:absolute inset-x-0 bottom-0 pb-4 px-2">${footerTextTemplate()} ${footerLinksTemplate()}</div>` : ""}
	</div>`;
};
