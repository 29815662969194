import { html } from "lit-html";
export function Accessibility() {
  return html`<div class="m-2">
		<h1 class="text-xl py-4">Accessibility</h1>
		<p class="mb-4">
			Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Volutpat consequat mauris nunc congue nisi. Est placerat in
			egestas erat imperdiet sed euismod nisi porta. Viverra accumsan in nisl nisi scelerisque eu. Diam maecenas ultricies mi eget. Metus aliquam eleifend mi in nulla posuere sollicitudin
			aliquam. Tincidunt tortor aliquam nulla facilisi cras fermentum odio eu. Volutpat lacus laoreet non curabitur gravida arcu. Tempor orci eu lobortis elementum nibh tellus. Orci sagittis eu
			volutpat odio. Sit amet nisl purus in. In metus vulputate eu scelerisque felis imperdiet. Tortor at risus viverra adipiscing at in tellus integer feugiat. Accumsan lacus vel facilisis
			volutpat est velit egestas dui id. Morbi tristique senectus et netus et malesuada fames.
		</p>
		<p>
			Porttitor rhoncus dolor purus non enim. Convallis convallis tellus id interdum. Purus semper eget duis at tellus at. Sed turpis tincidunt id aliquet risus. Leo integer malesuada nunc vel
			risus commodo viverra maecenas accumsan. Massa tempor nec feugiat nisl. Cras tincidunt lobortis feugiat vivamus at augue eget arcu. Et netus et malesuada fames ac turpis egestas. Sapien
			faucibus et molestie ac. Mauris pellentesque pulvinar pellentesque habitant morbi. Massa enim nec dui nunc. Dolor sed viverra ipsum nunc aliquet bibendum. Aliquam etiam erat velit
			scelerisque in dictum non consectetur. Cursus metus aliquam eleifend mi. Tincidunt eget nullam non nisi. Non odio euismod lacinia at quis risus sed. Porttitor lacus luctus accumsan tortor
			posuere ac ut consequat semper.
		</p>
	</div>`;
}
