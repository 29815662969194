import { Money } from "./../../../Common/Money";
import { Service } from "./Service";
export class CustomPricedService extends Service {
  constructor(_data) {
    super();
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof CustomPricedService
     */
    this.$type = "V1.Orders.Create.Request.CustomPricedService";
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    super.init(_data);
    if (_data) {
      if (_data["Price"]) {
        const price_ = new Money();
        this.Price = price_.init(_data["Price"]);
      }
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    super.toJSON(_data);
    _data["Price"] = this.Price ? this.Price.toJSON() : void 0;
    return _data;
  }
}
