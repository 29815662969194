import { SubscriptionIdentifier } from "./SubscriptionIdentifier";
export class VatRegistrationSubscription extends SubscriptionIdentifier {
  constructor(_data) {
    super();
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof VatRegistrationSubscription
     */
    this.$type = "V1.Subscriptions.VatRegistrationSubscription";
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    super.init(_data);
    if (_data) {
      this.EventType = VatRegistrationSubscriptionEventType[_data["EventType"]];
      this.VatRegistrationId = _data["VatRegistrationId"];
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    super.toJSON(_data);
    _data["EventType"] = VatRegistrationSubscriptionEventType[this.EventType];
    _data["VatRegistrationId"] = this.VatRegistrationId;
    return _data;
  }
}
export var VatRegistrationSubscriptionEventType = /* @__PURE__ */ ((VatRegistrationSubscriptionEventType2) => {
  VatRegistrationSubscriptionEventType2["ESLTaxReturnType"] = "ESLTaxReturnType";
  VatRegistrationSubscriptionEventType2["VLSTaxReturnType"] = "VLSTaxReturnType";
  return VatRegistrationSubscriptionEventType2;
})(VatRegistrationSubscriptionEventType || {});
