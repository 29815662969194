var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { html } from "lit-html";
import { useClient, useEffect, useLoadingReducer, useQueryState, useState } from "GlobalShared/haunted/CustomHooks";
import i18next from "i18next";
import { currentLocale } from "../../globals";
import { isEmpty } from "GlobalShared/helpers/stringHelper";
import { Address } from "WebServices/APIs/self/models/V1/Common";
import { Name } from "WebServices/APIs/self/models/V1/Common/Person";
import { useEnglishWorldCountries, useEUCountries, useServiceCountries, useWorldCountries } from "WebComponents/hooks/WebCustomHooks";
import { notifyError, notifySuccess, withErrorHandling } from "GlobalShared/helpers/errorHelper";
import { Companies as CompaniesClient, Vies as ViesClient, Company as CompanyClient } from "WebServices/APIs/self/clients";
import { updateParameter } from "GlobalShared/router";
import { useOrderSummary } from "GlobalShared/components/orders/order-summary";
import { priceBreakdownTemplate } from "GlobalShared/components/orders/order-price-breakdown";
import { generatePDFContract } from "GlobalShared/components/orders/contract/pdf-generator";
import { CreateCompanyRequest } from "WebServices/APIs/self/models/V1/Companies/Create";
import { IndividualDetail } from "WebServices/APIs/self/models/V1/Companies/IndividualDetail";
import { CompanyDetail, CompanyDetailLegalStatus } from "WebServices/APIs/self/models/V1/Companies/CompanyDetail";
import { DEFAULT_CURRENCY_CODE } from "GlobalShared/components/orders/OrderModels";
import { useBusinessDetails } from "GlobalShared/components/orders/business-details/business-details";
import { useOrderServices } from "GlobalShared/components/orders/services/order-services";
import { mapBusinessDetails } from "GlobalShared/components/orders/business-details/BusinessDetailsModels";
import { getFeeSummary, createPriceConfig } from "GlobalShared/components/orders/services/order-services-utils";
import { PRICE_CONFIG_MOCK } from "GlobalShared/components/orders/services/OrderServiceModels";
import { useCommonCalls } from "./admin-common-calls";
import { mapToCreatedServices } from "GlobalShared/components/orders/services/CreateServiceModels";
import { getCompanyName } from "GlobalShared/helpers/servicesHelper";
const DEV_MODE = ClientContext.FeatureFlags.DevMode;
const STEPS = {
  BUSINESS_DETAILS: 0,
  COUNTRY_SELECTOR: 1,
  SERVICES: 2,
  SUMMARY: 3
};
export const PROGRESS_ITEMS = [i18next.t("Main Data"), i18next.t("Core Service"), i18next.t("Additional Services"), i18next.t("Summary")];
export function AdminSignup() {
  const getCreateCompanyRequest = () => {
    const businessDetail = {
      Phone: businessDetailsHook.state.phone,
      EstablishmentCountryCode: businessDetailsHook.state.countryOfEstablishment,
      VatNumber: isEmpty(businessDetailsHook.state.vatNumber) ? "" : `${businessDetailsHook.state.countryOfEstablishment}${businessDetailsHook.state.vatNumber}`,
      ContactPosition: businessDetailsHook.state.contactPosition,
      Address: new Address({
        City: businessDetailsHook.state.addressCity,
        Zip: businessDetailsHook.state.addressZip,
        Street: businessDetailsHook.state.addressStreet,
        StreetNumber: businessDetailsHook.state.addressStreetNo,
        Province: businessDetailsHook.state.addressProvince
      })
    };
    return new CreateCompanyRequest({
      BusinessDetail: businessDetailsHook.state.legalStatus === "Individual" ? new IndividualDetail(__spreadProps(__spreadValues({}, businessDetail), {
        Name: new Name({
          Title: businessDetailsHook.state.contactTitle,
          FirstName: businessDetailsHook.state.contactFirstName,
          LastName: businessDetailsHook.state.contactLastName
        })
      })) : new CompanyDetail(__spreadProps(__spreadValues({}, businessDetail), {
        LegalStatus: businessDetailsHook.state.legalStatus === "Company" ? CompanyDetailLegalStatus.Company : CompanyDetailLegalStatus.Partnership,
        CompanyName: businessDetailsHook.state.companyName,
        ContactName: new Name({
          Title: businessDetailsHook.state.contactTitle,
          FirstName: businessDetailsHook.state.contactFirstName,
          LastName: businessDetailsHook.state.contactLastName
        })
      })),
      PreferredLanguage: businessDetailsHook.state.preferredLanguage
    });
  };
  const loadAvailableServices = () => __async(this, null, function* () {
    const availableServices = yield commonCalls.getAvailableServices(DEFAULT_CURRENCY_CODE, servicesHook.state.vatCountrySelection, servicesHook.state.iossSelection);
    setPriceConfig(createPriceConfig(availableServices));
  });
  const createCompany = () => __async(this, null, function* () {
    yield withErrorHandling(
      () => __async(this, null, function* () {
        var _a;
        const companyId = (_a = yield companiesClient.signUp({ body: getCreateCompanyRequest() })) == null ? void 0 : _a.data;
        setCurrentCompanyId(companyId);
      }),
      (error) => {
        notifyError(error);
      }
    );
  });
  const loadCompanyBusinessDetail = () => __async(this, null, function* () {
    return withErrorHandling(
      () => __async(this, null, function* () {
        if (!isNewCompany) {
          const result = yield companyClient.getCompanyDetail({ locale: currentLocale });
          setOrigBusinessDetails(mapBusinessDetails(result.data));
        }
      }),
      (error) => {
        notifyError(error);
      }
    );
  });
  const checkVatNumber = (fullVatNumber) => __async(this, null, function* () {
    const result = yield viesClient.checkVatNumber({
      fullVatNumber
    });
    return result.data === "Valid";
  });
  const createOrder = () => __async(this, null, function* () {
    return withErrorHandling(
      () => __async(this, null, function* () {
        const orderResult = yield commonCalls.createOrder(currentCompanyId, mapToCreatedServices(true, /* @__PURE__ */ new Date(), priceConfig, servicesHook.state));
        if (orderResult) {
          setCreatedOrderModel(__spreadProps(__spreadValues({}, createdOrderModel), {
            Id: orderResult.Id,
            ContractId: orderResult.ContractId,
            ClientSource: orderResult.ClientSource,
            OrderSummary: orderResult.OrderSummary
          }));
          notifySuccess("Order successfully saved");
        }
      }),
      (error) => {
        notifyError(error);
      }
    );
  });
  const [priceConfig, setPriceConfig] = useState(void 0);
  const [loadingServices, dispatchLoadingServices] = useLoadingReducer();
  const [currentStep, setCurrentStep] = useState(0);
  const [createdOrderModel, setCreatedOrderModel] = useState({});
  const [currentCompanyId, setCurrentCompanyId] = useState(void 0);
  const [isNewCompany] = useQueryState("newcompany", false);
  const [origBusinessDetails, setOrigBusinessDetails] = useState(void 0);
  const worldCountries = useWorldCountries(currentLocale);
  const englishWorldCountries = useEnglishWorldCountries();
  const serviceCountries = useServiceCountries(currentLocale);
  const euCountries = useEUCountries(currentLocale);
  const [feeSummary, setFeeSummary] = useState(void 0);
  const commonCalls = useCommonCalls();
  const businessDetailsHook = useBusinessDetails({
    devMode: DEV_MODE,
    servicesFlowType: "Signup",
    worldCountries,
    euCountries,
    checkVatNumber,
    origBusinessDetails
  });
  const servicesHook = useOrderServices({
    devMode: DEV_MODE,
    isAdmin: false,
    isSignup: true,
    countryOfEstablishment: businessDetailsHook.state.countryOfEstablishment,
    servicesFlowType: "Signup",
    euCountries,
    worldCountries,
    serviceCountries,
    priceConfig,
    priceBreakdownTemplate: () => priceBreakdownTemplate(feeSummary),
    loadingServices
  });
  const orderSummaryHook = useOrderSummary({
    isAdmin: false,
    priceBreakdownTemplate: () => priceBreakdownTemplate(feeSummary),
    isParentValid: businessDetailsHook.isValid && servicesHook.isValid,
    order: createdOrderModel,
    hasContractReference: false,
    isSignup: true,
    companyId: currentCompanyId,
    generateContractPdf: () => {
      var _a;
      const pdfModel = {
        partnerName: getCompanyName(
          businessDetailsHook.state.legalStatus,
          businessDetailsHook.state.companyName,
          businessDetailsHook.state.contactFirstName,
          businessDetailsHook.state.contactLastName
        ),
        legalStatus: businessDetailsHook.state.legalStatus,
        addressLine: `${businessDetailsHook.state.addressStreet} ${businessDetailsHook.state.addressStreetNo}, ${businessDetailsHook.state.addressZip} ${businessDetailsHook.state.addressCity} ${englishWorldCountries.find((wc) => wc.Code === businessDetailsHook.state.countryOfEstablishment).Name}`,
        summary: __spreadProps(__spreadValues({}, feeSummary), {
          countries: feeSummary.countries.map((sc) => __spreadProps(__spreadValues({}, sc), {
            label: englishWorldCountries.find((wc) => wc.Code === sc.countryCode).Name
          }))
        }),
        contractDate: /* @__PURE__ */ new Date(),
        iossTier: (_a = servicesHook.state.iossSelection) == null ? void 0 : _a.tier,
        vatNumber: businessDetailsHook.state.vatNumber ? businessDetailsHook.state.countryOfEstablishment + businessDetailsHook.state.vatNumber : "",
        kpmgReference: createdOrderModel == null ? void 0 : createdOrderModel.ContractId,
        hasVatService: servicesHook.state.vatCountrySelection.length > 0,
        clientSource: createdOrderModel == null ? void 0 : createdOrderModel.ClientSource
      };
      return generatePDFContract(pdfModel);
    },
    getPaymentInfo: commonCalls.getPaymentInfo,
    payAndConfirm: commonCalls.payAndConfirm,
    uploadContract: commonCalls.uploadContract,
    downloadContract: () => {
      return void 0;
    }
  });
  const [nextInProgress, setNextInProgress] = useState(false);
  useEffect(() => {
    if (priceConfig && businessDetailsHook.state && servicesHook.state && euCountries && serviceCountries) {
      const tempFeeSummary = getFeeSummary(
        false,
        /* @__PURE__ */ new Date(),
        priceConfig,
        businessDetailsHook.state.countryOfEstablishment,
        businessDetailsHook.state.vatEUValid,
        servicesHook.state,
        euCountries,
        serviceCountries
      );
      setFeeSummary(tempFeeSummary);
    }
  }, [priceConfig, businessDetailsHook.state, servicesHook.state, euCountries, serviceCountries, createdOrderModel.ClientSource]);
  useEffect(() => __async(this, null, function* () {
    if (currentCompanyId) {
      if (DEV_MODE && PRICE_CONFIG_MOCK) {
        setPriceConfig(PRICE_CONFIG_MOCK);
      } else {
        dispatchLoadingServices("inc");
        try {
          yield loadAvailableServices();
        } finally {
          dispatchLoadingServices("dec");
        }
      }
    }
  }), [servicesHook.state.iossSelection, servicesHook.state.vatCountrySelection]);
  const companiesClient = useClient(CompaniesClient);
  const companyClient = useClient(CompanyClient);
  const viesClient = useClient(ViesClient);
  const init = () => __async(this, null, function* () {
    updateParameter("lnglrt", "true");
    if (ClientContext.CompanyId) {
      yield loadCompanyBusinessDetail();
    }
  });
  useEffect(init, []);
  useEffect(() => {
    if (currentStep !== 3) {
      orderSummaryHook.resetSummary();
    }
  }, [currentStep]);
  const htmlFlow = () => {
    return html`<div class="flex justify-around w-full my-4">
				<dc-step-progressbar
					.items=${PROGRESS_ITEMS}
					.activeIndex=${currentStep}
					@click=${(e) => {
      if (e.detail.step < currentStep) {
        setCurrentStep(e.detail.step);
      }
    }}
				></dc-step-progressbar>
			</div>
			${currentStep === 0 ? businessDetailsHook.mainTemplate() : ""} ${currentStep === 1 ? servicesHook.htmlMainSelector() : ""} ${currentStep === 2 ? servicesHook.htmlAdditionalServices() : ""}
			${currentStep === 3 ? orderSummaryHook.mainTemplate() : ""}
			<div class="mt-12 flex w-full justify-between">
				<button
					class="px-4 h-12 border-2 border-brand-primary-light rounded text-brand-primary text-sm bg-white font-semibold ${currentStep === 0 ? "invisible" : ""}"
					@click="${() => {
      if (currentStep === STEPS.SUMMARY) {
        setCurrentStep(STEPS.SERVICES);
      } else if (currentStep === STEPS.SERVICES) {
        setCurrentStep(STEPS.COUNTRY_SELECTOR);
      } else if (currentStep === STEPS.COUNTRY_SELECTOR) {
        setCurrentStep(STEPS.BUSINESS_DETAILS);
      }
    }}}"
				>
					${i18next.t("Previous")}
				</button>
				<button
					class="btn btn-primary h-12 ${currentStep === STEPS.SUMMARY ? "invisible" : ""}"
					?disabled=${currentStep === STEPS.BUSINESS_DETAILS && !businessDetailsHook.isValid || currentStep === STEPS.COUNTRY_SELECTOR && !servicesHook.isValidMainSelector || currentStep === STEPS.SERVICES && !servicesHook.isValid || nextInProgress || loadingServices.count > 0}
					@click=${() => __async(this, null, function* () {
      if (currentStep === STEPS.BUSINESS_DETAILS) {
        if (businessDetailsHook.isValid) {
          try {
            setNextInProgress(true);
            yield createCompany();
            yield loadAvailableServices();
            setCurrentStep(STEPS.COUNTRY_SELECTOR);
          } finally {
            setNextInProgress(false);
          }
        }
      } else if (currentStep === STEPS.COUNTRY_SELECTOR) {
        if (servicesHook.isValidMainSelector) {
          setCurrentStep(STEPS.SERVICES);
        }
      } else if (currentStep === STEPS.SERVICES) {
        if (servicesHook.isValid) {
          try {
            setNextInProgress(true);
            yield createOrder();
            setCurrentStep(STEPS.SUMMARY);
          } finally {
            setNextInProgress(false);
          }
        }
      }
    })}
				>
					${nextInProgress ? `${i18next.t("Loading")}...` : i18next.t("Next")}
				</button>
			</div>`;
  };
  return html`<div class="my-2 mx-8">
		<div class="xl:w-2/3 m-auto">
			<div class="flex justify-around w-full">
				<div class="text-2xl font-bold text-gray-800 mt-8 mb-6">${i18next.t("Getting started with KPMG")}</div>
			</div>
			${htmlFlow()}
		</div>
	</div>`;
}
