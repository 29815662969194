import { CountryBasedSubscriptionService, SubscriptionService, SimpleService } from "WebServices/APIs/self/models/V1/Orders/Create/Request";
export const mapCreatedServices = (createdServices) => {
  return createdServices.map((cs) => {
    if (cs.Type === "Country") {
      return new CountryBasedSubscriptionService({
        Code: cs.Code,
        Country: cs.Country,
        StartDate: cs.StartDate,
        EndDate: cs.EndDate
      });
    } else if (cs.Type === "Simple") {
      return new SimpleService({
        Code: cs.Code
      });
    } else if (cs.Type === "GlobalSubscription") {
      return new SubscriptionService({
        Code: cs.Code,
        StartDate: cs.StartDate,
        EndDate: cs.EndDate
      });
    } else {
      throw new Error("Unknown created service: " + cs.Code);
    }
  });
};
