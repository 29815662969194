var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { useComplianceUploads } from "GlobalShared/components/compliance-uploads/compliance-uploads";
import {
  ALL_STATUS,
  mapComplianceUpload
} from "GlobalShared/components/compliance-uploads/ComplianceUploadModels";
import { useClient } from "GlobalShared/haunted/CustomHooks";
import { notifyError, withErrorHandling } from "GlobalShared/helpers/errorHelper";
import { simulateDownload } from "GlobalShared/helpers/fileHelper";
import { mapCompanyDetailToCompanyServicesModel, mapDefaultCompanyConfiguration } from "GlobalShared/mappers/ServicesMapper";
import { DUMMY_PERMISSION_MANAGER } from "GlobalShared/models/common/PermissionModels";
import { useWorldCountries } from "WebComponents/hooks/WebCustomHooks";
import { Company as CompanyClient, ComplianceMasterData as ComplianceMasterDataClient, ComplianceUploads as ComplianceUploadsClient } from "WebServices/APIs/self/clients";
import { ComplianceCompany as ComplianceCompanyClient } from "WebServices/APIs/self/clients/ComplianceCompany";
import { UpdatedCompanyConfiguration } from "WebServices/APIs/self/models/V1/ComplianceUploads/Configuration";
import { UpdatedAmazonConfiguration, UpdatedSellerEntity } from "WebServices/APIs/self/models/V1/ComplianceUploads/Configuration/Amazon";
import { Field, Filter, Pager, SearchRequest } from "WebServices/APIs/self/models/V1/ComplianceUploads/Search";
import { FieldOrder } from "WebServices/APIs/self/models/V1/ComplianceUploads/Search/Field";
import { currentLocale, getRouteByKey } from "../../globals";
export function AdminComplianceUpload() {
  const getFilter = (showAll) => {
    const clientFilter = new Filter();
    clientFilter.Statuses = showAll ? ALL_STATUS : ALL_STATUS.filter((f) => f !== "DataUploadFinalized" && f !== "Error" && f !== "SentToReturnGeneration" && f !== "DataVatGenerated" && f !== "UploadProcessed");
    return clientFilter;
  };
  const mapValidationMessages = (messages) => {
    return messages ? messages.map((msg) => {
      const vm = {
        validationCode: msg.ValidationCode,
        columnName: msg.ColumnName,
        columnCode: msg.ColumnCode,
        parameters: msg.Parameters,
        rowNumber: msg.RowNumber,
        totalCount: msg.TotalCount,
        isWarning: msg.IsWarning
      };
      return vm;
    }) : [];
  };
  const uploadIossTransactions = (complianceUploadId, ignoreWarnings, file) => __async(this, null, function* () {
    const uploadResult = yield complianceUploadClient.uploadIossTransactions({
      complianceUploadId,
      ignoreWarnings,
      file
    });
    const validationMessages = mapValidationMessages(uploadResult.data.ValidationMessages);
    const uploadResultVM = {
      // TODO: give back file reference too
      fileReference: void 0,
      validationMessages,
      numberOfTotalLinesWithError: uploadResult.data.NumberOfTotalLinesWithError
    };
    return uploadResultVM;
  });
  const uploadVatTransactions = (complianceUploadId, ignoreWarnings, file) => __async(this, null, function* () {
    const uploadResult = yield complianceUploadClient.uploadVatKpmgTransactions({
      complianceUploadId,
      ignoreWarnings,
      file
    });
    const validationMessages = mapValidationMessages(uploadResult.data.ValidationMessages);
    const uploadResultVM = {
      // TODO: give back file reference too
      fileReference: void 0,
      validationMessages,
      numberOfTotalLinesWithError: uploadResult.data.NumberOfTotalLinesWithError
    };
    return uploadResultVM;
  });
  const removeUploadedVatTransactions = (complianceUploadId) => __async(this, null, function* () {
    return withErrorHandling(
      () => __async(this, null, function* () {
        yield complianceUploadClient.removeUploadedVatKpmgTransactions({
          complianceUploadId
        });
      }),
      (error) => {
        notifyError(error);
      }
    );
  });
  const uploadSaftTransactions = (complianceUploadId, file) => __async(this, null, function* () {
    const uploadResult = yield complianceUploadClient.uploadSaftTransactions({
      complianceUploadId,
      file
    });
    const validationMessages = mapValidationMessages(uploadResult.data.ValidationMessages);
    const uploadResultVM = {
      // TODO: give back file reference too
      fileReference: void 0,
      validationMessages,
      numberOfTotalLinesWithError: uploadResult.data.NumberOfTotalLinesWithError
    };
    return uploadResultVM;
  });
  const uploadAmazonTransactions = (complianceUploadId, sellerEntityId, ignoreWarnings, file) => __async(this, null, function* () {
    const uploadResult = yield complianceUploadClient.uploadAmazonTransactions({
      complianceUploadId,
      sellerEntityId,
      ignoreWarnings,
      file
    });
    const validationMessages = mapValidationMessages(uploadResult.data.ValidationMessages);
    const uploadResultVM = {
      // TODO: give back file reference too
      fileReference: void 0,
      validationMessages,
      numberOfTotalLinesWithError: uploadResult.data.NumberOfTotalLinesWithError
    };
    return uploadResultVM;
  });
  const removeUploadedAmazonTransactions = (complianceUploadId) => __async(this, null, function* () {
    return withErrorHandling(
      () => __async(this, null, function* () {
        yield complianceUploadClient.removeUploadedAmazonTransactions({
          complianceUploadId
        });
      }),
      (error) => {
        notifyError(error);
      }
    );
  });
  const finalizeComplianceUpload = (complianceUpload, confirmNoSaft, acknowledgeIncompleteSkus) => __async(this, null, function* () {
    if (complianceUpload.Type === "IOSS") {
      yield complianceUploadClient.finalizeIossComplianceUpload({
        complianceUploadId: complianceUpload.Id
      });
    } else {
      if (complianceUpload.Status === "New") {
        yield complianceUploadClient.finalizeVatComplianceUpload({
          complianceUploadId: complianceUpload.Id
        });
      } else if (complianceUpload.Status === "SaftCompliance") {
        yield complianceUploadClient.finalizeSAFT({
          complianceUploadId: complianceUpload.Id,
          confirmNoSaft: confirmNoSaft === void 0 ? false : confirmNoSaft
        });
      } else if (complianceUpload.Status === "MasterDataSetup") {
        yield complianceUploadClient.finalizeMasterData({
          complianceUploadId: complianceUpload.Id,
          acknowledgeIncompleteSkus: acknowledgeIncompleteSkus === void 0 ? false : acknowledgeIncompleteSkus
        });
      }
    }
  });
  const searchComplianceUploads = (gridState, showAll) => __async(this, null, function* () {
    const response = yield complianceUploadClient.searchComplianceUploads({
      body: new SearchRequest({
        Filter: getFilter(showAll),
        Pager: new Pager({
          Skip: gridState.pageIndex * gridState.pageSize,
          Top: gridState.pageSize,
          OrderBy: [
            new Field({
              Order: gridState.orderDir === "asc" ? FieldOrder.Ascending : FieldOrder.Descending,
              OrderByField: gridState.orderBy
            })
          ]
        })
      })
    });
    const vm = {
      uploads: response.data.ComplianceUploads.map(mapComplianceUpload),
      total: response.data.Count
    };
    return vm;
  });
  const generateSaftTemplate = (complianceUploadId) => __async(this, null, function* () {
    return withErrorHandling(
      () => __async(this, null, function* () {
        const response = yield complianceUploadClient.generateSaftTemplate({
          complianceUploadId
        });
        simulateDownload(response.data);
      }),
      (error) => {
        notifyError(error);
      }
    );
  });
  const exportComplianceUploads = (_gridState, _showAll) => __async(this, null, function* () {
  });
  const getDefaultCompanyConfiguration = () => __async(this, null, function* () {
    const response = yield complianceCompanyClient.getDefaultConfiguration();
    return mapDefaultCompanyConfiguration(response.data);
  });
  const getCompanyServices = () => __async(this, null, function* () {
    return withErrorHandling(
      () => __async(this, null, function* () {
        const companyDetailResult = yield companyClient.getCompanyDetail({
          locale: currentLocale
        });
        return mapCompanyDetailToCompanyServicesModel(worldCountries, companyDetailResult.data);
      }),
      (error) => {
        notifyError(error);
      }
    );
  });
  const updateDefaultCompanyConfiguration = (companyConfiguration) => __async(this, null, function* () {
    yield complianceCompanyClient.updateDefaultConfiguration({
      body: new UpdatedCompanyConfiguration({
        AmazonConfiguration: companyConfiguration.amazonConfiguration ? new UpdatedAmazonConfiguration({
          SellerEntities: companyConfiguration.amazonConfiguration.sellerEntities.map(
            (se) => new UpdatedSellerEntity({
              Id: se.id,
              Name: se.name,
              MwsAuthToken: se.mwsAuthToken,
              IsEnabled: se.isEnabled,
              IsAutomated: se.isAutomated
            })
          ),
          UseAmazonRates: companyConfiguration.amazonConfiguration.useAmazonRates,
          RefundMode: companyConfiguration.amazonConfiguration.refundMode
        }) : void 0,
        UseKpmgTemplate: companyConfiguration.useKpmgTemplate,
        UseAmazonTemplate: companyConfiguration.useAmazonTemplate
      })
    });
  });
  const hasAnyIncompleteSKUs = () => __async(this, null, function* () {
    return withErrorHandling(
      () => __async(this, null, function* () {
        const companyDetailResult = yield complianceMasterDataClient.hasAnyIncompleteSKUs();
        return companyDetailResult.data;
      }),
      (error) => {
        notifyError(error);
      }
    );
  });
  const reconfirmUpload = (complianceUploadId) => __async(this, null, function* () {
    return withErrorHandling(
      () => __async(this, null, function* () {
        yield complianceUploadClient.checkBalance({
          complianceUploadId
        });
        const compliance = yield complianceUploadClient.getCompliance({
          complianceUploadId
        });
        return compliance.data.Status;
      }),
      (error) => {
        notifyError(error);
      }
    );
  });
  const cancelUpload = (_complianceUploadId) => __async(this, null, function* () {
    return void 0;
  });
  const resendNotification = (_complianceUploadId) => __async(this, null, function* () {
  });
  const regenerateDataVat = (_complianceUploadId) => __async(this, null, function* () {
  });
  const generateTextFile = (_complianceUploadId) => __async(this, null, function* () {
  });
  const changeIgnoreOverdue = (_complianceUploadId, _ignoreOverdue) => __async(this, null, function* () {
  });
  const worldCountries = useWorldCountries(currentLocale);
  const companyClient = useClient(CompanyClient);
  const complianceCompanyClient = useClient(ComplianceCompanyClient);
  const complianceMasterDataClient = useClient(ComplianceMasterDataClient);
  const complianceUploadClient = useClient(ComplianceUploadsClient);
  const complianceUploadHook = useComplianceUploads({
    isAdmin: false,
    permissionManager: DUMMY_PERMISSION_MANAGER,
    uploadIossTransactions,
    uploadVatTransactions,
    removeUploadedVatTransactions,
    uploadAmazonTransactions,
    removeUploadedAmazonTransactions,
    uploadSaftTransactions,
    generateSaftTemplate,
    finalizeComplianceUpload,
    searchComplianceUploads,
    exportComplianceUploads,
    getDefaultCompanyConfiguration,
    getCompanyServices,
    updateDefaultCompanyConfiguration,
    hasAnyIncompleteSKUs,
    getRouteByKey,
    resendNotification,
    regenerateDataVat,
    generateTextFile,
    reconfirmUpload,
    cancelUpload,
    changeIgnoreOverdue
  });
  return complianceUploadHook.mainTemplate;
}
