export class MovingSubReason {
  constructor(_data) {
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof MovingSubReason
     */
    this.$type = "V1.Companies.Subscriptions.Feedbacks.MovingSubReason";
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      this.Opinion = _data["Opinion"];
      this.Option = MovingSubReasonOption[_data["Option"]];
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    _data["Opinion"] = this.Opinion;
    _data["Option"] = MovingSubReasonOption[this.Option];
    return _data;
  }
}
export var MovingSubReasonOption = /* @__PURE__ */ ((MovingSubReasonOption2) => {
  MovingSubReasonOption2["Pricing"] = "Pricing";
  MovingSubReasonOption2["ServiceQuality"] = "ServiceQuality";
  MovingSubReasonOption2["ScopeCoverage"] = "ScopeCoverage";
  MovingSubReasonOption2["Other"] = "Other";
  return MovingSubReasonOption2;
})(MovingSubReasonOption || {});
