import { IndividualDetail as WebIndividualBusinessDetail, CompanyDetail as WebCompanyBusinessDetail } from "WebServices/APIs/self/models/V1/CompanyInfo";
function getLegalStatus(companyDetail) {
  if (companyDetail instanceof WebIndividualBusinessDetail) {
    return "Individual";
  } else {
    if (companyDetail instanceof WebCompanyBusinessDetail) {
      return companyDetail.LegalStatus;
    }
  }
  return "";
}
export const mapBusinessDetails = (companyDetail) => {
  var _a;
  const vm = {
    legalStatus: getLegalStatus(companyDetail.BusinessDetail),
    phone: companyDetail.ContactPhone,
    companyName: void 0,
    contactTitle: void 0,
    contactFirstName: void 0,
    contactLastName: void 0,
    contactPosition: companyDetail.BusinessDetail.ContactPosition,
    addressCity: companyDetail.BusinessDetail.Address.City,
    addressZip: companyDetail.BusinessDetail.Address.Zip,
    addressStreet: companyDetail.BusinessDetail.Address.Street,
    addressStreetNo: companyDetail.BusinessDetail.Address.StreetNumber,
    addressProvince: companyDetail.BusinessDetail.Address.Province,
    preferredLanguage: companyDetail.ContactLanguage,
    countryOfEstablishment: companyDetail.BusinessDetail.EstablishmentCountryCode,
    vatNumber: (_a = companyDetail.BusinessDetail.VatNumber) == null ? void 0 : _a.substring(2),
    vatEUValid: companyDetail.IsVatNumberValid
  };
  if (companyDetail.BusinessDetail instanceof WebIndividualBusinessDetail) {
    vm.contactTitle = companyDetail.BusinessDetail.Name.Title;
    vm.contactFirstName = companyDetail.BusinessDetail.Name.FirstName;
    vm.contactLastName = companyDetail.BusinessDetail.Name.LastName;
  } else if (companyDetail.BusinessDetail instanceof WebCompanyBusinessDetail) {
    vm.companyName = companyDetail.BusinessDetail.CompanyName;
    vm.contactTitle = companyDetail.BusinessDetail.ContactName.Title;
    vm.contactFirstName = companyDetail.BusinessDetail.ContactName.FirstName;
    vm.contactLastName = companyDetail.BusinessDetail.ContactName.LastName;
  }
  return vm;
};
