import { footerLinksTemplate, footerTextTemplate, headerTemplate } from "./../../shared/templates/commons";
import { getParameter } from "GlobalShared/router";
import { useState } from "haunted";
import i18next from "i18next";
import { html } from "lit-html";
import { getLocalizedUrl } from "../globals";
import { validateEmail } from "GlobalShared/validators/email-validator";
import { validatePassword } from "GlobalShared/validators/password-validator";
export const observedAttributes = ["email", "error", "area"];
export const name = "ww-reset-password";
export const Component = (host) => {
  var _a;
  const props = {
    email: host.email,
    error: host.error,
    area: host.area
  };
  const generateError = () => {
    if (props.error) {
      return props.error;
    }
    if (email && !validateEmail(email)) {
      return i18next.t("Please provide a valid e-mail address.");
    }
    if (password && !validatePassword(password)) {
      return i18next.t(
        "Password must be at least 8 digits long and contain any three of the following four qualities: Uppercase Characters, Lowercase Characters, Alphanumeric Characters, Special Characters."
      );
    }
    if (password && confirmPassword && password !== confirmPassword) {
      return i18next.t("The two passwords must be the same.");
    }
    return void 0;
  };
  const hasError = () => {
    return !email || !password || !confirmPassword || generateError() !== void 0 ? true : false;
  };
  const [email, setEmail] = useState(props.email);
  const [password, setPassword] = useState(void 0);
  const [confirmPassword, setConfirmPassword] = useState(void 0);
  return html`
		<div class="min-h-screen m-auto relative sm:pb-20">
			${headerTemplate()}
			${getParameter("success") !== void 0 ? html`
						<div class="bg-white bg-opacity-75 text-black rounded px-8 mt-40 pb-4 m-auto flex flex-col max-w-sm mb-16">
							<div class="md:text-xl text-center font-bold py-6">${i18next.t("Password successfully reset")}</div>
							<div class="m-auto">
								<a class="btn btn-primary" href=${getLocalizedUrl(`/account/login/${(_a = props.area) != null ? _a : ""}`)} data-routing="server"> ${i18next.t("Sign in")} </a>
							</div>
						</div>
				  ` : html`
						<div class="bg-white bg-opacity-75 text-black rounded px-8 pb-4 m-auto flex flex-col max-w-sm mb-16">
							<div class="text-3xl font-bold py-6">${i18next.t("Reset Password")}</div>
							<div class="mb-2">
								<dc-input
									.label=${i18next.t("Email")}
									.name=${"Email"}
									.value=${email}
									.placeholder=${i18next.t("Email")}
									@change=${(e) => setEmail(e.detail.value)}
								></dc-input>
							</div>
							<div class="mb-2">
								<dc-input
									.label=${i18next.t("New password")}
									.type=${"password"}
									.name=${"Password"}
									.value=${password}
									.placeholder=${"*************"}
									@change=${(e) => setPassword(e.detail.value)}
								></dc-input>
							</div>
							<div class="mb-2">
								<dc-input
									.label=${i18next.t("Confirm new password")}
									.type=${"password"}
									.name=${"confirmPassword"}
									.value=${confirmPassword}
									.placeholder=${"*************"}
									@change=${(e) => setConfirmPassword(e.detail.value)}
								></dc-input>
							</div>
							${props.area !== void 0 ? html`<input type="hidden" value=${props.area} name=${"area"} />` : ""}
							${hasError() ? html`<div class="mb-2">
										<label class="text-red-400"> ${generateError()} </label>
								  </div>` : ""}
							<div class="m-auto">
								<button class="btn btn-primary" type="submit">${i18next.t("Set Password")}</button>
							</div>
						</div>
						<div class="w-full mt-4 sm:absolute inset-x-0 bottom-0 pb-4 px-2">${footerTextTemplate()} ${footerLinksTemplate()}</div>
				  `}
		</div>
	`;
};
