var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { html, TemplateResult } from "lit-html";
import { useState } from "haunted";
import { alreadySignedUp, getLocalizedUrl, getRouteByKey } from "../globals";
import { addHandler, navigateTo, removeOptionals } from "GlobalShared/router";
import { useClient, useEffect, useMemo } from "GlobalShared/haunted/CustomHooks";
import i18next from "i18next";
import { Companies as CompaniesClient } from "WebServices/APIs/self/clients";
import { ChangeCompany } from "WebServices/APIs/self/models/V1/Companies";
import { notifyError, withErrorHandling } from "GlobalShared/helpers/errorHelper";
import { useNotification } from "./notification/notification";
const MAIN_MENU = [
  {
    label: i18next.t("Services"),
    key: "admin/services(/manage)",
    icon: "M464 32H48C21.49 32 0 53.49 0 80v352c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V80c0-26.51-21.49-48-48-48zm-6 400H54a6 6 0 0 1-6-6V86a6 6 0 0 1 6-6h404a6 6 0 0 1 6 6v340a6 6 0 0 1-6 6zm-42-92v24c0 6.627-5.373 12-12 12H204c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h200c6.627 0 12 5.373 12 12zm0-96v24c0 6.627-5.373 12-12 12H204c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h200c6.627 0 12 5.373 12 12zm0-96v24c0 6.627-5.373 12-12 12H204c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h200c6.627 0 12 5.373 12 12zm-252 12c0 19.882-16.118 36-36 36s-36-16.118-36-36 16.118-36 36-36 36 16.118 36 36zm0 96c0 19.882-16.118 36-36 36s-36-16.118-36-36 16.118-36 36-36 36 16.118 36 36zm0 96c0 19.882-16.118 36-36 36s-36-16.118-36-36 16.118-36 36-36 36 16.118 36 36z"
  },
  {
    label: i18next.t("Business Details"),
    key: "admin/business-details",
    icon: "M527.9 224H480v-48c0-26.5-21.5-48-48-48H272l-64-64H48C21.5 64 0 85.5 0 112v288c0 26.5 21.5 48 48 48h400c16.5 0 31.9-8.5 40.7-22.6l79.9-128c20-31.9-3-73.4-40.7-73.4zM48 118c0-3.3 2.7-6 6-6h134.1l64 64H426c3.3 0 6 2.7 6 6v42H152c-16.8 0-32.4 8.8-41.1 23.2L48 351.4zm400 282H72l77.2-128H528z"
  },
  {
    label: i18next.t("Client Accounts"),
    key: "admin/client-accounts",
    icon: "M336 0H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V48c0-26.5-21.5-48-48-48zm0 464H48V48h288v416zM144 112h96c8.8 0 16-7.2 16-16s-7.2-16-16-16h-96c-8.8 0-16 7.2-16 16s7.2 16 16 16zm48 176c35.3 0 64-28.7 64-64s-28.7-64-64-64-64 28.7-64 64 28.7 64 64 64zm-89.6 128h179.2c12.4 0 22.4-8.6 22.4-19.2v-19.2c0-31.8-30.1-57.6-67.2-57.6-10.8 0-18.7 8-44.8 8-26.9 0-33.4-8-44.8-8-37.1 0-67.2 25.8-67.2 57.6v19.2c0 10.6 10 19.2 22.4 19.2z"
  },
  {
    label: i18next.t("Order History"),
    key: "admin/orders(/:orderId)",
    icon: "M377 105L279.1 7c-4.5-4.5-10.6-7-17-7H256v128h128v-6.1c0-6.3-2.5-12.4-7-16.9zm-153 31V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2 0-24-10.8-24-24zM64 72c0-4.42 3.58-8 8-8h80c4.42 0 8 3.58 8 8v16c0 4.42-3.58 8-8 8H72c-4.42 0-8-3.58-8-8V72zm0 80v-16c0-4.42 3.58-8 8-8h80c4.42 0 8 3.58 8 8v16c0 4.42-3.58 8-8 8H72c-4.42 0-8-3.58-8-8zm144 263.88V440c0 4.42-3.58 8-8 8h-16c-4.42 0-8-3.58-8-8v-24.29c-11.29-.58-22.27-4.52-31.37-11.35-3.9-2.93-4.1-8.77-.57-12.14l11.75-11.21c2.77-2.64 6.89-2.76 10.13-.73 3.87 2.42 8.26 3.72 12.82 3.72h28.11c6.5 0 11.8-5.92 11.8-13.19 0-5.95-3.61-11.19-8.77-12.73l-45-13.5c-18.59-5.58-31.58-23.42-31.58-43.39 0-24.52 19.05-44.44 42.67-45.07V232c0-4.42 3.58-8 8-8h16c4.42 0 8 3.58 8 8v24.29c11.29.58 22.27 4.51 31.37 11.35 3.9 2.93 4.1 8.77.57 12.14l-11.75 11.21c-2.77 2.64-6.89 2.76-10.13.73-3.87-2.43-8.26-3.72-12.82-3.72h-28.11c-6.5 0-11.8 5.92-11.8 13.19 0 5.95 3.61 11.19 8.77 12.73l45 13.5c18.59 5.58 31.58 23.42 31.58 43.39 0 24.53-19.05 44.44-42.67 45.07z"
  },
  {
    label: i18next.t("Data Upload"),
    key: "admin/compliance-upload",
    icon: "M296 384h-80c-13.3 0-24-10.7-24-24V192h-87.7c-17.8 0-26.7-21.5-14.1-34.1L242.3 5.7c7.5-7.5 19.8-7.5 27.3 0l152.2 152.2c12.6 12.6 3.7 34.1-14.1 34.1H320v168c0 13.3-10.7 24-24 24zm216-8v112c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V376c0-13.3 10.7-24 24-24h136v8c0 30.9 25.1 56 56 56h80c30.9 0 56-25.1 56-56v-8h136c13.3 0 24 10.7 24 24zm-124 88c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20zm64 0c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20z"
  },
  {
    label: i18next.t("Master Data"),
    key: "admin/master-data",
    icon: "M567.403 235.642L462.323 84.589A48 48 0 0 0 422.919 64H153.081a48 48 0 0 0-39.404 20.589L8.597 235.642A48.001 48.001 0 0 0 0 263.054V400c0 26.51 21.49 48 48 48h480c26.51 0 48-21.49 48-48V263.054c0-9.801-3-19.366-8.597-27.412zM153.081 112h269.838l77.913 112H75.168l77.913-112zM528 400H48V272h480v128zm-32-64c0 17.673-14.327 32-32 32s-32-14.327-32-32 14.327-32 32-32 32 14.327 32 32zm-96 0c0 17.673-14.327 32-32 32s-32-14.327-32-32 14.327-32 32-32 32 14.327 32 32z",
    items: [
      {
        label: i18next.t("Amazon SKUs"),
        key: "admin/master-data/amazon-skus",
        icon: html`&#9679;`
      },
      {
        label: i18next.t("VAT Rates"),
        key: "admin/master-data/default-vat-rates",
        icon: html`&#9679;`
      }
    ]
  },
  {
    label: i18next.t("Return Library"),
    key: "admin/return-library",
    icon: "M448 360V24c0-13.3-10.7-24-24-24H96C43 0 0 43 0 96v320c0 53 43 96 96 96h328c13.3 0 24-10.7 24-24v-16c0-7.5-3.5-14.3-8.9-18.7-4.2-15.4-4.2-59.3 0-74.7 5.4-4.3 8.9-11.1 8.9-18.6zM128 134c0-3.3 2.7-6 6-6h212c3.3 0 6 2.7 6 6v20c0 3.3-2.7 6-6 6H134c-3.3 0-6-2.7-6-6v-20zm0 64c0-3.3 2.7-6 6-6h212c3.3 0 6 2.7 6 6v20c0 3.3-2.7 6-6 6H134c-3.3 0-6-2.7-6-6v-20zm253.4 250H96c-17.7 0-32-14.3-32-32 0-17.6 14.4-32 32-32h285.4c-1.9 17.1-1.9 46.9 0 64z"
  }
];
const isCurrentMenu = (key) => window.location.pathname.startsWith(removeOptionals(getRouteByKey(key)));
const startsWith = (pathPart) => window.location.pathname.substring("/gb/en/".length).startsWith(pathPart);
export function Header() {
  const onToggleMenu = () => {
    setMenuOpen(!menuOpen);
  };
  const addNewCompany = () => {
    window.location.href = getLocalizedUrl("/account-manager");
  };
  const [menuOpen, setMenuOpen] = useState(false);
  const [, setCurrentPathname] = useState(window.location.pathname);
  const currentCompanyId = useMemo(() => {
    return parseInt(ClientContext.CompanyId);
  }, []);
  const currentCompanies = useMemo(() => {
    return ClientContext.Companies.map((c) => ({ id: parseInt(c.Id), name: c.Name }));
  }, []);
  const companiesClient = useClient(CompaniesClient);
  const notificationHook = useNotification();
  const init = () => {
    const adminHomePath = getRouteByKey("admin");
    const guidesAndTutorialsPath = getRouteByKey("guides-and-tutorials");
    const faqsPath = getRouteByKey("faqs");
    const contactPath = getRouteByKey("contact");
    if (!alreadySignedUp() && ![adminHomePath, guidesAndTutorialsPath, faqsPath, contactPath].includes(window.location.pathname)) {
      console.log("reroute");
      navigateTo(adminHomePath);
    }
    return addHandler("webheader", (location) => {
      setCurrentPathname(location.pathname);
    });
  };
  useEffect(init, []);
  const menuItemLeafTemplate = (menuItem) => {
    return html`<a
			href=${removeOptionals(getRouteByKey(menuItem.key))}
			@click=${(e) => {
      if (alreadySignedUp()) {
        setMenuOpen(false);
      }
      if (!e.ctrlKey) {
        e.preventDefault();
        e.stopPropagation();
        navigateTo(removeOptionals(getRouteByKey(menuItem.key)));
      }
    }}
			><div
				class="${alreadySignedUp() ? "text-white" : "text-gray-500"} py-2 pl-6 text-lg mx-2 mt-1 font-semibold hover:bg-brand-primary-light rounded ${isCurrentMenu(menuItem.key) ? "bg-brand-primary-light" : ""}"
			>
				${menuItem.icon instanceof TemplateResult ? menuItem.icon : html`<svg class="inline mr-2" width="24" height="24" viewBox="0 0 500 600" fill="${alreadySignedUp() ? "white" : "#999"}">
							<path d=${menuItem.icon}></path>
					  </svg>`}
				${menuItem.label}
			</div></a
		>`;
  };
  const menuItemTemplate = (menuItem) => {
    if (menuItem.hidden !== true) {
      if (menuItem.items) {
        return html`<div>
					<div>${menuItemLeafTemplate(menuItem)}</div>
					<div class="ml-4 ${!startsWith(menuItem.key) ? "hidden" : ""}">${menuItem.items.map(menuItemTemplate)}</div>
				</div>`;
      } else {
        return menuItemLeafTemplate(menuItem);
      }
    } else {
      return "";
    }
  };
  const htmlGuidesAndTutorials = () => {
    return html`<div class="w-full text-white sm:absolute inset-x bottom-0 mb-4">
			<a
				class="${window.location.pathname === getRouteByKey("guides-and-tutorials") ? "bg-brand-primary-light" : ""} mx-2 py-2 pl-6 hover:bg-brand-primary-light rounded block text-white"
				href=${getRouteByKey("guides-and-tutorials")}
				><div class="flex items-center">
					<svg class="inline mr-1" width="23" height="23" viewBox="0 0 500 550" fill="white">
						<path
							d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z"
						></path>
					</svg>
					<span class="text-lg font-semibold ml-1"> ${i18next.t("Guides and Tutorials")}</span>
				</div>
			</a>
			<a
				class="${window.location.pathname === getRouteByKey("faqs") ? "bg-brand-primary-light" : ""} mx-2 py-2 pl-6 hover:bg-brand-primary-light rounded block text-white"
				href=${getRouteByKey("faqs")}
				><div class="flex items-center">
					<svg class="inline mr-1" width="23" height="23" viewBox="0 0 500 550" fill="white">
						<path
							d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zM262.655 90c-54.497 0-89.255 22.957-116.549 63.758-3.536 5.286-2.353 12.415 2.715 16.258l34.699 26.31c5.205 3.947 12.621 3.008 16.665-2.122 17.864-22.658 30.113-35.797 57.303-35.797 20.429 0 45.698 13.148 45.698 32.958 0 14.976-12.363 22.667-32.534 33.976C247.128 238.528 216 254.941 216 296v4c0 6.627 5.373 12 12 12h56c6.627 0 12-5.373 12-12v-1.333c0-28.462 83.186-29.647 83.186-106.667 0-58.002-60.165-102-116.531-102zM256 338c-25.365 0-46 20.635-46 46 0 25.364 20.635 46 46 46s46-20.636 46-46c0-25.365-20.635-46-46-46z"
						></path>
					</svg>
					<span class="text-lg font-semibold ml-1 mt-1"> ${i18next.t("FAQ")}</span>
				</div>
			</a>
			<div class="my-4 w-full border border-t border-brand-primary"></div>
			<a
				class="${window.location.pathname === getRouteByKey("contact") ? "bg-brand-primary-light" : ""} mx-2 py-2 pl-6 hover:bg-brand-primary-light rounded block text-white"
				href=${getRouteByKey("contact")}
			>
				<span class="text-lg"> ${i18next.t("Contact")} </span>
			</a>
		</div>`;
  };
  const menuTemplate = () => {
    return html`<div id="menu" style="width: 280px;" class="${menuOpen ? "block" : "hidden"} bg-brand-primary-dark fixed md:block sm:h-full">
			<div>
				<div class="pl-4 pt-4 hidden md:block">
					<a href="${getRouteByKey("admin")}">
						<div class="text-center">
							<div>
								<img src="/images/kpmg_logo_white.png" alt="KPMG logo" width="90" height="36" />
							</div>
						</div>
					</a>
				</div>
				<button type="button" @click=${onToggleMenu} class="block text-white h-16 ml-4 focus:text-brand-primary hover:text-brand-primary focus:outline-none md:invisible">
					<svg class="h-4 w-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
						<path d="M10 8.586L2.929 1.515 1.515 2.929 8.586 10l-7.071 7.071 1.414 1.414L10 11.414l7.071 7.071 1.414-1.414L11.414 10l7.071-7.071-1.414-1.414L10 8.586z"></path>
					</svg>
				</button>
				<div class="flex flex-col justify-between h-full">
					<div>${MAIN_MENU.map(menuItemTemplate)}</div>
					<div>${htmlGuidesAndTutorials()}</div>
				</div>
			</div>
		</div>`;
  };
  const htmlCompanySelector = () => {
    return html`<div class="flex space-x-2 items-center mr-2">
			<dc-select
				class="w-64"
				.dataSource=${currentCompanies.map((c) => ({ value: c.id.toString(), label: c.name }))}
				.selectedValues=${currentCompanyId == null ? void 0 : currentCompanyId.toString()}
				@change=${(e) => __async(this, null, function* () {
      const companyId = parseInt(e.detail.selectedValue);
      yield withErrorHandling(
        () => __async(this, null, function* () {
          yield companiesClient.changeCompany({ body: new ChangeCompany({ CompanyId: companyId }) });
          window.location.reload();
        }),
        (error) => {
          notifyError(error);
        }
      );
    })}
			></dc-select>
			<div class="mx-2">
				<button
					type="button"
					@click=${() => __async(this, null, function* () {
      yield withErrorHandling(
        () => __async(this, null, function* () {
          yield companiesClient.changeCompany({
            body: new ChangeCompany({ CompanyId: void 0 })
          });
          addNewCompany();
        }),
        (error) => {
          notifyError(error);
        }
      );
    })}
					class="btn btn-primary btn-small"
				>
					${i18next.t("New company")}
				</button>
			</div>
		</div>`;
  };
  const headerTemplate = () => {
    return html`<div class="fixed bg-white h-16 flex w-full pr-4 justify-between sm:items-center select-none border-b-2 border-gray-300">
			<div class="relative flex items-center justify-between sm:p-0">
				<div>
					${!menuOpen ? html`<button type="button" @click=${onToggleMenu} class="block ml-4 text-brand-primary focus:text-brand-secondary hover:text-brand-secondary focus:outline-none">
								<svg class="h-4 w-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
									<path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"></path>
								</svg>
						  </button>` : ""}
				</div>
				<div class="pl-2 flex items-center md:hidden">
					<a href="${getRouteByKey("admin")}">
						<div class="text-center">
							<div>
								<img src="/images/kpmg_logo.png" alt="KPMG logo" width="90" height="36" />
							</div>
						</div>
					</a>
				</div>
			</div>

			<div class="flex items-center h-full">
				${ClientContext.Companies.length > 0 ? htmlCompanySelector() : ""}
				<span class="border-r border-gray-300 h-full"></span>
				<ww-profile-select></ww-profile-select>
				<span class="border-r border-gray-300 h-full"></span>
				<ww-language-select></ww-language-select>
			</div>
		</div>`;
  };
  return html`<header class="relative z-10">${headerTemplate()}${menuTemplate()}${notificationHook.mainTemplate()}<dc-notifier></dc-notifier></header>`;
}
