var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { useAmazonSkus } from "GlobalShared/components/sku/amazon-skus";
import {
  mapGtuCodes,
  mapSearchResult,
  mapSkuValidationMessage,
  SkuFieldNames
} from "GlobalShared/components/sku/AmazonSkuModels";
import { useClient } from "GlobalShared/haunted/CustomHooks";
import { withErrorHandling } from "GlobalShared/helpers/errorHelper";
import { simulateDownload } from "GlobalShared/helpers/fileHelper";
import { DUMMY_PERMISSION_MANAGER } from "GlobalShared/models/common/PermissionModels";
import { component } from "haunted";
import { ComplianceMasterData as ComplianceMasterDataClient } from "WebServices/APIs/self/clients";
import { AmountRange } from "WebServices/APIs/self/models/V1/AmountRange";
import { Money } from "WebServices/APIs/self/models/V1/Common";
import { AddSku, UpdateSku } from "WebServices/APIs/self/models/V1/ComplianceMasterData";
import { ExportRequest, Field, Filter, Pager, SearchRequest } from "WebServices/APIs/self/models/V1/ComplianceMasterData/SearchSku";
import { FieldOrder } from "WebServices/APIs/self/models/V1/ComplianceMasterData/SearchSku/Field";
export function AdminAmazonSkus() {
  const getFilter = (gridState, showOnlyMissing) => {
    const clientFilter = new Filter();
    clientFilter.ShowOnlyMissing = showOnlyMissing;
    if (gridState.appliedFilters.length > 0) {
      gridState.appliedFilters.forEach((filter) => {
        if (filter.field === SkuFieldNames.SkuId) {
          if (filter.filterProps.selectedValues) {
            clientFilter.Sku = filter.filterProps.selectedValues;
          }
        }
        if (filter.field === SkuFieldNames.SellerEntityId) {
          if (filter.filterProps.selectedValues) {
            clientFilter.SellerEntityId = filter.filterProps.selectedValues;
          }
        }
        if (filter.field === SkuFieldNames.CommodityCode) {
          if (filter.filterProps.selectedValues) {
            clientFilter.CommodityCode = filter.filterProps.selectedValues;
          }
        }
        if (filter.field === SkuFieldNames.ItemIdentifier) {
          if (filter.filterProps.selectedValues) {
            clientFilter.ItemIdentifiers = filter.filterProps.selectedValues;
          }
        }
        if (filter.field === SkuFieldNames.Price) {
          if (filter.filterProps.min || filter.filterProps.max) {
            clientFilter.Price = new AmountRange({
              From: filter.filterProps.min !== void 0 ? filter.filterProps.min : 0,
              To: filter.filterProps.max !== void 0 ? filter.filterProps.max : 1e6
            });
          }
        }
      });
    }
    return clientFilter;
  };
  const loadSkus = (gridState, showOnlyMissing) => __async(this, null, function* () {
    const response = yield complianceMasterDataClient.searchSKUs({
      body: new SearchRequest({
        Filter: getFilter(gridState, showOnlyMissing),
        Pager: new Pager({
          Skip: gridState.pageIndex * gridState.pageSize,
          Top: gridState.pageSize,
          OrderBy: [
            new Field({
              Order: gridState.orderDir === "asc" ? FieldOrder.Ascending : FieldOrder.Descending,
              OrderByField: gridState.orderBy
            })
          ]
        })
      })
    });
    return mapSearchResult(response.data);
  });
  const loadGtuCodes = () => __async(this, null, function* () {
    return withErrorHandling(
      () => __async(this, null, function* () {
        const response = yield complianceMasterDataClient.getGTUCodes();
        return mapGtuCodes(response.data);
      }),
      (error) => {
        throw error;
      }
    );
  });
  const downloadSkus = (exportPageSize, gridState, showOnlyMissing) => __async(this, null, function* () {
    const response = yield complianceMasterDataClient.exportSkus({
      body: new ExportRequest({
        Filter: getFilter(gridState, showOnlyMissing),
        PageSize: exportPageSize
      })
    });
    simulateDownload(response.data);
  });
  const downloadCurrencies = () => __async(this, null, function* () {
    const response = yield complianceMasterDataClient.exportCurrencies();
    simulateDownload(response.data);
  });
  const uploadSkus = (file) => __async(this, null, function* () {
    return withErrorHandling(
      () => __async(this, null, function* () {
        const uploadResult = yield complianceMasterDataClient.uploadSkus({
          file
        });
        return {
          isSuccess: uploadResult.data.IsSuccess,
          validationMessages: mapSkuValidationMessage(uploadResult.data.ValidationMessages ? uploadResult.data.ValidationMessages : [])
        };
      }),
      (error) => {
        throw error;
      }
    );
  });
  const updateSku = (sku) => __async(this, null, function* () {
    withErrorHandling(
      () => __async(this, null, function* () {
        yield complianceMasterDataClient.updateSku({
          id: sku.Id,
          body: new UpdateSku({
            CommodityCode: sku.CommodityCode,
            ItemIdentifier: sku.ItemIdentifier,
            Price: new Money(__spreadValues({}, sku.Price))
          })
        });
      }),
      (error) => {
        throw error;
      }
    );
  });
  const addSku = (sku) => __async(this, null, function* () {
    withErrorHandling(
      () => __async(this, null, function* () {
        yield complianceMasterDataClient.addSku({
          body: new AddSku({
            SkuId: sku.SkuId,
            SellerEntityId: sku.SellerEntityId,
            CommodityCode: sku.CommodityCode,
            ItemIdentifier: sku.ItemIdentifier,
            Price: new Money(__spreadValues({}, sku.Price))
          })
        });
      }),
      (error) => {
        throw error;
      }
    );
  });
  const removeSku = (skuId) => __async(this, null, function* () {
    withErrorHandling(
      () => __async(this, null, function* () {
        yield complianceMasterDataClient.removeSku({ id: skuId });
      }),
      (error) => {
        throw error;
      }
    );
  });
  const complianceMasterDataClient = useClient(ComplianceMasterDataClient);
  const amazonSkusHook = useAmazonSkus({
    isAdmin: false,
    permissionManager: DUMMY_PERMISSION_MANAGER,
    loadSkus,
    loadGtuCodes,
    downloadSkus,
    uploadSkus,
    updateSku,
    addSku,
    removeSku,
    downloadCurrencies
  });
  return amazonSkusHook.mainTemplate;
}
customElements.define("ww-admin-amazon-skus", component(AdminAmazonSkus, { useShadowDOM: false }));
