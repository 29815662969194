import { BusinessDetail } from "./BusinessDetail";
import { CompanyDetail } from "./CompanyDetail";
import { IndividualDetail } from "./IndividualDetail";
export class BusinessDetailFactory {
  static create($type) {
    if ($type === "V1.Companies.BusinessDetail") {
      const result = new BusinessDetail();
      return result;
    }
    if ($type === "V1.Companies.CompanyDetail") {
      const result = new CompanyDetail();
      return result;
    }
    if ($type === "V1.Companies.IndividualDetail") {
      const result = new IndividualDetail();
      return result;
    }
    throw new Error($type + " not found");
  }
}
