var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { useClient, useEffect, useState } from "GlobalShared/haunted/CustomHooks";
import i18next from "i18next";
import { html } from "lit-html";
import { Companies as CompaniesClient } from "WebServices/APIs/self/clients";
import { notifyError, notifySuccess, withErrorHandling } from "GlobalShared/helpers/errorHelper";
import { EXPIRED_SUBSCRIPTION_TYPE_LABELS, mapSubscriptionToExpiredSubscriptionVM } from "./NotificationModels";
import { formatDate } from "GlobalShared/helpers/dateHelper";
import { navigateTo } from "GlobalShared/router";
import { getLocalizedUrl } from "../../globals";
import { DeregistrationReason, Feedback, MovingReason, MovingSubReason } from "WebServices/APIs/self/models/V1/Companies/Subscriptions/Feedbacks";
const getLastSignedInFromSessionStorage = (companyId) => {
  return sessionStorage.getItem("LastSignedIn:" + companyId);
};
const saveLastSignedInToSessionStorage = (companyId, token) => {
  return sessionStorage.setItem("LastSignedIn:" + companyId, token);
};
export const useNotification = () => {
  const saveFeedback = (expiredSubscription) => __async(void 0, null, function* () {
    yield withErrorHandling(
      () => __async(void 0, null, function* () {
        yield companiesClient.addSubscriptionFeedback({
          subscriptionId: expiredSubscription.id,
          body: new Feedback({
            LeavingReasons: [
              expiredSubscription.reason === "Deregistration" ? new DeregistrationReason() : new MovingReason({
                Reasons: expiredSubscription.movingReasons.map(
                  (mr) => new MovingSubReason({
                    Option: mr,
                    Opinion: mr === "Other" ? expiredSubscription.otherReasonText : void 0
                  })
                )
              })
            ]
          })
        });
        setExpiredSubscriptions(expiredSubscriptions.filter((es) => es.id !== expiredSubscription.id));
        notifySuccess(i18next.t("Feedback saved successfully"));
      }),
      (error) => {
        notifyError(error);
      }
    );
  });
  const updateExpiredSubscription = (newExpiredSubscription) => {
    setExpiredSubscriptions(expiredSubscriptions.map((es) => es.id === newExpiredSubscription.id ? newExpiredSubscription : es));
  };
  const getExpiredSubscriptions = () => __async(void 0, null, function* () {
    return withErrorHandling(
      () => __async(void 0, null, function* () {
        const result = yield companiesClient.getExpiringSubscriptions();
        return result.data.map(mapSubscriptionToExpiredSubscriptionVM);
      }),
      (error) => {
        notifyError(error);
      }
    );
  });
  const [showNotificationModal, setShowNotificationModal] = useState(true);
  const companiesClient = useClient(CompaniesClient);
  const [expiredSubscriptions, setExpiredSubscriptions] = useState(void 0);
  const init = () => __async(void 0, null, function* () {
    if (ClientContext.CompanyId) {
      const fromSession = getLastSignedInFromSessionStorage(ClientContext.CompanyId);
      if (ClientContext.SignedInUtc !== void 0 && ClientContext.SignedInUtc !== fromSession) {
        saveLastSignedInToSessionStorage(ClientContext.CompanyId, ClientContext.SignedInUtc);
        const tempExpiredSubscriptions = yield getExpiredSubscriptions();
        if (tempExpiredSubscriptions.length > 0) {
          setExpiredSubscriptions(tempExpiredSubscriptions);
          setShowNotificationModal(true);
        }
      }
    }
  });
  useEffect(init, []);
  const htmlNotificationModal = () => {
    if (showNotificationModal && expiredSubscriptions) {
      return html`
				<div class="mappings-modal-container">
					<dc-modal
						.visible=${showNotificationModal}
						@close=${() => setShowNotificationModal(false)}
						.header=${i18next.t("Notifications")}
						.content=${html`<div>
							<div class="space-y-4">
								<div>${i18next.t("Service expiration:")}</div>
								<div>
									${i18next.t(
        "The below services are about to expire, please go to Services menu and extend your subscription. If you don\u2019t want to extend these services, please leave a feedback."
      )}
								</div>
								<ul class="list-inside list-disc">
									${expiredSubscriptions.map(
        (es) => html`<li class="flex items-center space-x-4 space-y-2">
													<div>${EXPIRED_SUBSCRIPTION_TYPE_LABELS[es.type]}${es.countryCode ? html` (${es.countryCode})` : ""}: ${formatDate(es.endDate)}</div>
													<button
														class="btn btn-small btn-primary"
														@click=${() => {
          updateExpiredSubscription(__spreadProps(__spreadValues({}, es), { opened: true }));
        }}
													>
														I don't want to renew
													</button>
												</li>
												${es.opened ? html`<notification-feedback
															.expiredSubscription=${es}
															.save=${saveFeedback}
															.cancel=${() => {
          updateExpiredSubscription(__spreadProps(__spreadValues({}, es), { opened: false }));
        }}
													  ></notification-feedback>` : ""}`
      )}
								</ul>
								<div>
									<button
										class="btn btn-primary"
										@click=${() => {
        setShowNotificationModal(false);
        navigateTo(getLocalizedUrl("admin/services/manage"));
      }}
									>
										Renew Services
									</button>
								</div>
							</div>
						</div>`}
					>
					</dc-modal>
				</div>
			`;
    } else {
      return html``;
    }
  };
  const mainTemplate = () => {
    return html`${htmlNotificationModal()}`;
  };
  return {
    mainTemplate
  };
};
