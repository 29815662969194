import { User } from "./User";
export class SearchResult {
  constructor(_data) {
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof SearchResult
     */
    this.$type = "V1.Account.Search.SearchResult";
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      this.Count = _data["Count"];
      if (_data["Users"] && _data["Users"].constructor === Array) {
        this.Users = [];
        for (let item of _data["Users"]) {
          const users_ = new User();
          this.Users.push(users_.init(item));
        }
      }
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    _data["Count"] = this.Count;
    if (this.Users && this.Users.constructor === Array) {
      _data["Users"] = [];
      for (let item of this.Users) {
        _data["Users"].push(item.toJSON());
      }
    }
    return _data;
  }
}
