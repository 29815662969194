var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { useClient, useEffect, useState } from "GlobalShared/haunted/CustomHooks";
import { html } from "lit-html";
import { getCurrentFullPath } from "GlobalShared/router";
import { Account as AccountClient } from "WebServices/APIs/self/clients";
import i18next from "i18next";
import "./profile-select.scss";
import { extractFirstErrorMessage, notifyError, notifySuccess, notifyWarning, withErrorHandling } from "GlobalShared/helpers/errorHelper";
import { validateEmail } from "GlobalShared/validators/email-validator";
import { UpdateEmail } from "WebServices/APIs/self/models/V1/Account";
export const observedAttributes = [];
export const useShadowDOM = false;
export const name = "ww-profile-select";
export const Component = (host) => {
  const saveNewEmail = () => __async(void 0, null, function* () {
    setEmailValidationMessage("");
    if (validateEmail(newEmail)) {
      yield withErrorHandling(
        () => __async(void 0, null, function* () {
          accountClient.changeEmail({
            body: new UpdateEmail({
              Email: newEmail
            })
          });
          notifySuccess(i18next.t("Email change request sent to your new email address. Please check your new inbox"));
        }),
        (error) => {
          if (error.statusCode === 409) {
            notifyError(i18next.t("Email already exists"));
          } else {
            if (error.statusCode >= 400 && error.statusCode < 500) {
              notifyWarning(extractFirstErrorMessage(error, i18next.t("Error occured")));
            } else {
              notifyError(error);
            }
          }
        },
        () => {
          setShowEmailChangeModal(false);
        }
      );
    } else {
      setEmailValidationMessage(i18next.t("Invalid email format"));
    }
  });
  const onToggleProfileMenu = () => {
    setProfileMenuOpen(!profileMenuOpen);
  };
  const [profileMenuOpen, setProfileMenuOpen] = useState(false);
  const [newEmail, setNewEmail] = useState(void 0);
  const [showEmailChangeModal, setShowEmailChangeModal] = useState(false);
  const [emailValidationMessage, setEmailValidationMessage] = useState("");
  const accountClient = useClient(AccountClient);
  const init = () => {
    const onClickedOutside = (e) => {
      if (!host.contains(e.target)) {
        setProfileMenuOpen(false);
      }
    };
    const onCloseOnEscKey = (e) => {
      if (e.key === "Escape") {
        setProfileMenuOpen(false);
      }
    };
    document.addEventListener("keyup", onCloseOnEscKey, true);
    document.addEventListener("click", onClickedOutside);
    return () => {
      document.removeEventListener("keyup", onCloseOnEscKey);
      document.removeEventListener("click", onClickedOutside);
    };
  };
  useEffect(init, []);
  const htmlEmailChangeModal = () => {
    if (showEmailChangeModal) {
      return html`
				<div>
					<dc-modal
						.customClass=${"changing-email-modal"}
						.visible=${showEmailChangeModal}
						@close=${() => {
        setShowEmailChangeModal(false);
      }}
						.header=${i18next.t("Change email address")}
						.content=${html`<div>
							<dc-input
								class="w-80"
								.label=${i18next.t("New email address")}
								.value=${newEmail}
								.validationMessage=${emailValidationMessage}
								@change=${(e) => {
        setNewEmail(e.detail.value);
      }}
							></dc-input>
							<div class="mt-4 flex space-x-4">
								<button
									class="btn btn-primary"
									@click=${() => {
        setShowEmailChangeModal(false);
      }}
								>
									${i18next.t("Cancel")}
								</button>
								<button class="btn btn-primary" @click=${() => __async(void 0, null, function* () {
        return saveNewEmail();
      })}>${i18next.t("Save")}</button>
							</div>
						</div>`}
					>
					</dc-modal>
				</div>
			`;
    } else {
      return html``;
    }
  };
  return html`<div class="relative bg-white w-full">
		<a @click=${onToggleProfileMenu} class="text-gray-800 font-semibold cursor-pointer px-4 flex justify-between select-none">
			${ClientContext.UserName}
			<div class="${profileMenuOpen ? "-rotate-90" : "rotate-90"} block transform ml-2">&#10095;</div>
		</a>
		<div class="${profileMenuOpen ? "block  shadow-lg" : "hidden"} absolute bg-white w-full border border-t-1 border-gray-300">
			<div class="relative px-4 py-2">
				<form method="POST" action="/account/logout">
					<input type="hidden" name="returnUrl" value="${getCurrentFullPath()}" />
					<button class="w-full text-left" type="submit">${i18next.t("Logout")}</button>
				</form>
			</div>
			<div class="px-4 py-2">
				<button
					class="w-full text-left"
					@click=${() => {
    setProfileMenuOpen(false);
    setShowEmailChangeModal(true);
    setNewEmail("");
  }}
				>
					${i18next.t("Change email address")}
				</button>
			</div>
		</div>
		${htmlEmailChangeModal()}
	</div>`;
};
