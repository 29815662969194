var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { useClientAccounts } from "GlobalShared/components/client-accounts/client-accounts";
import { useClient } from "GlobalShared/haunted/CustomHooks";
import { toSafeNumber } from "GlobalShared/helpers/numberHelper";
import { Account as AccountClient } from "WebServices/APIs/self/clients";
import { PatchCompanies } from "WebServices/APIs/self/models/V1/Account/PatchCompanies";
import { InviteUser } from "WebServices/APIs/self/models/V1/Account";
import { SearchRequest as AccountSearchRequest, Field as AccountSearchField, Filter as AccountSearchFilter, Pager as AccountSearchPager } from "WebServices/APIs/self/models/V1/Account/Search";
import { FieldOrder as AccountSearchFieldOrder } from "WebServices/APIs/self/models/V1/Account/Search/Field";
import { DUMMY_PERMISSION_MANAGER } from "GlobalShared/models/common/PermissionModels";
export function AdminClientAccounts() {
  const searchUserCompanies = (gridState, emailFragment, companyNameFragment) => __async(this, null, function* () {
    const filter = new AccountSearchFilter();
    filter.EmailPart = emailFragment;
    filter.Company = companyNameFragment;
    const userSearchResult = yield accountClient.searchUsers({
      body: new AccountSearchRequest({
        Filter: filter,
        Pager: new AccountSearchPager({
          Skip: gridState.pageIndex * gridState.pageSize,
          Top: gridState.pageSize,
          OrderBy: [
            new AccountSearchField({
              Order: gridState.orderDir === "asc" ? AccountSearchFieldOrder.Ascending : AccountSearchFieldOrder.Descending,
              OrderByField: gridState.orderBy
            })
          ]
        })
      })
    });
    return {
      userCompanies: userSearchResult.data.Users.map((u) => {
        const ret = {
          userId: u.Id,
          email: u.Email,
          companies: u.Companies.map((c) => ({
            id: c.Id,
            name: c.Name
          }))
        };
        return ret;
      }),
      total: userSearchResult.data.Count
    };
  });
  const searchCompanies = (companyNameFragment) => __async(this, null, function* () {
    return ClientContext.Companies.filter((c) => c.Name.toLowerCase().includes(companyNameFragment.toLowerCase())).map((c) => ({ id: toSafeNumber(c.Id), name: c.Name }));
  });
  const inviteUser = (email, companies) => __async(this, null, function* () {
    yield accountClient.inviteUser({
      body: new InviteUser({ Email: email, CompanyIds: companies.map((c) => c.id) })
    });
  });
  const assignCompanies = (userId, addedCompanies, removedCompanies) => __async(this, null, function* () {
    yield accountClient.patchCompanies({
      userId,
      body: new PatchCompanies({
        AddedCompanies: addedCompanies.map((c) => c.id),
        RemovedCompanies: removedCompanies.map((c) => c.id)
      })
    });
  });
  const changeEmail = (_userId, _newEmail) => __async(this, null, function* () {
  });
  const accountClient = useClient(AccountClient);
  const clientAccountsHook = useClientAccounts({
    isAdmin: false,
    permissionManager: DUMMY_PERMISSION_MANAGER,
    searchUserCompanies,
    searchCompanies,
    inviteUser,
    assignCompanies,
    changeEmail
  });
  return clientAccountsHook.mainTemplate;
}
