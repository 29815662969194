import { DateRange } from "./../../Common/DateTime/DateRange";
export class Filter {
  constructor(_data) {
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof Filter
     */
    this.$type = "V1.Submission.Search.Filter";
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      if (_data["ClientApprovalDueDate"]) {
        const clientApprovalDueDate_ = new DateRange();
        this.ClientApprovalDueDate = clientApprovalDueDate_.init(_data["ClientApprovalDueDate"]);
      }
      if (_data["FrequencyTypes"] && _data["FrequencyTypes"].constructor === Array) {
        this.FrequencyTypes = [];
        for (let item of _data["FrequencyTypes"]) {
          this.FrequencyTypes.push(FilterFrequencyTypes[item]);
        }
      }
      if (_data["PaymentDueDate"]) {
        const paymentDueDate_ = new DateRange();
        this.PaymentDueDate = paymentDueDate_.init(_data["PaymentDueDate"]);
      }
      this.PaymentProofUploaded = _data["PaymentProofUploaded"];
      if (_data["PaymentStatuses"] && _data["PaymentStatuses"].constructor === Array) {
        this.PaymentStatuses = [];
        for (let item of _data["PaymentStatuses"]) {
          this.PaymentStatuses.push(FilterPaymentStatuses[item]);
        }
      }
      if (_data["PeriodRange"]) {
        const periodRange_ = new DateRange();
        this.PeriodRange = periodRange_.init(_data["PeriodRange"]);
      }
      if (_data["Periods"] && _data["Periods"].constructor === Array) {
        this.Periods = [];
        for (let item of _data["Periods"]) {
          this.Periods.push(item);
        }
      }
      if (_data["ReturnGenerationDueDate"]) {
        const returnGenerationDueDate_ = new DateRange();
        this.ReturnGenerationDueDate = returnGenerationDueDate_.init(_data["ReturnGenerationDueDate"]);
      }
      this.ServiceCountryCode = _data["ServiceCountryCode"];
      if (_data["Statuses"] && _data["Statuses"].constructor === Array) {
        this.Statuses = [];
        for (let item of _data["Statuses"]) {
          this.Statuses.push(FilterStatuses[item]);
        }
      }
      if (_data["SubmissionDueDate"]) {
        const submissionDueDate_ = new DateRange();
        this.SubmissionDueDate = submissionDueDate_.init(_data["SubmissionDueDate"]);
      }
      if (_data["TaxReturnTypes"] && _data["TaxReturnTypes"].constructor === Array) {
        this.TaxReturnTypes = [];
        for (let item of _data["TaxReturnTypes"]) {
          this.TaxReturnTypes.push(FilterTaxReturnTypes[item]);
        }
      }
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    _data["ClientApprovalDueDate"] = this.ClientApprovalDueDate ? this.ClientApprovalDueDate.toJSON() : void 0;
    if (this.FrequencyTypes && this.FrequencyTypes.constructor === Array) {
      _data["FrequencyTypes"] = [];
      for (let item of this.FrequencyTypes) {
        _data["FrequencyTypes"].push(FilterFrequencyTypes[item]);
      }
    }
    _data["PaymentDueDate"] = this.PaymentDueDate ? this.PaymentDueDate.toJSON() : void 0;
    _data["PaymentProofUploaded"] = this.PaymentProofUploaded;
    if (this.PaymentStatuses && this.PaymentStatuses.constructor === Array) {
      _data["PaymentStatuses"] = [];
      for (let item of this.PaymentStatuses) {
        _data["PaymentStatuses"].push(FilterPaymentStatuses[item]);
      }
    }
    _data["PeriodRange"] = this.PeriodRange ? this.PeriodRange.toJSON() : void 0;
    if (this.Periods && this.Periods.constructor === Array) {
      _data["Periods"] = [];
      for (let item of this.Periods) {
        _data["Periods"].push(item);
      }
    }
    _data["ReturnGenerationDueDate"] = this.ReturnGenerationDueDate ? this.ReturnGenerationDueDate.toJSON() : void 0;
    _data["ServiceCountryCode"] = this.ServiceCountryCode;
    if (this.Statuses && this.Statuses.constructor === Array) {
      _data["Statuses"] = [];
      for (let item of this.Statuses) {
        _data["Statuses"].push(FilterStatuses[item]);
      }
    }
    _data["SubmissionDueDate"] = this.SubmissionDueDate ? this.SubmissionDueDate.toJSON() : void 0;
    if (this.TaxReturnTypes && this.TaxReturnTypes.constructor === Array) {
      _data["TaxReturnTypes"] = [];
      for (let item of this.TaxReturnTypes) {
        _data["TaxReturnTypes"].push(FilterTaxReturnTypes[item]);
      }
    }
    return _data;
  }
}
export var FilterFrequencyTypes = /* @__PURE__ */ ((FilterFrequencyTypes2) => {
  FilterFrequencyTypes2["Monthly"] = "Monthly";
  FilterFrequencyTypes2["Quarterly"] = "Quarterly";
  FilterFrequencyTypes2["QuarterlyShifted1"] = "QuarterlyShifted1";
  FilterFrequencyTypes2["QuarterlyShifted2"] = "QuarterlyShifted2";
  FilterFrequencyTypes2["BiMonthly"] = "BiMonthly";
  FilterFrequencyTypes2["FourMonthly"] = "FourMonthly";
  FilterFrequencyTypes2["SixMonthly"] = "SixMonthly";
  FilterFrequencyTypes2["Yearly"] = "Yearly";
  return FilterFrequencyTypes2;
})(FilterFrequencyTypes || {});
export var FilterPaymentStatuses = /* @__PURE__ */ ((FilterPaymentStatuses2) => {
  FilterPaymentStatuses2["Due"] = "Due";
  FilterPaymentStatuses2["Paid"] = "Paid";
  FilterPaymentStatuses2["PaidByClient"] = "PaidByClient";
  FilterPaymentStatuses2["NoPaymentNeeded"] = "NoPaymentNeeded";
  return FilterPaymentStatuses2;
})(FilterPaymentStatuses || {});
export var FilterStatuses = /* @__PURE__ */ ((FilterStatuses2) => {
  FilterStatuses2["New"] = "New";
  FilterStatuses2["DataUploaded"] = "DataUploaded";
  FilterStatuses2["ErrorReturnGen"] = "ErrorReturnGen";
  FilterStatuses2["ReturnGenerated"] = "ReturnGenerated";
  FilterStatuses2["ApprovedByClient"] = "ApprovedByClient";
  FilterStatuses2["RefusedByClient"] = "RefusedByClient";
  FilterStatuses2["ErrorAtTA"] = "ErrorAtTA";
  FilterStatuses2["SentToTA"] = "SentToTA";
  FilterStatuses2["Submitted"] = "Submitted";
  FilterStatuses2["Rejected"] = "Rejected";
  FilterStatuses2["SubmittedByClient"] = "SubmittedByClient";
  FilterStatuses2["NotToBeSubmitted"] = "NotToBeSubmitted";
  return FilterStatuses2;
})(FilterStatuses || {});
export var FilterTaxReturnTypes = /* @__PURE__ */ ((FilterTaxReturnTypes2) => {
  FilterTaxReturnTypes2["PrimaryVAT"] = "PrimaryVAT";
  FilterTaxReturnTypes2["SecondaryVAT"] = "SecondaryVAT";
  FilterTaxReturnTypes2["ESL"] = "ESL";
  FilterTaxReturnTypes2["VLS"] = "VLS";
  FilterTaxReturnTypes2["IOSS"] = "IOSS";
  FilterTaxReturnTypes2["Intrastat"] = "Intrastat";
  FilterTaxReturnTypes2["OSS"] = "OSS";
  return FilterTaxReturnTypes2;
})(FilterTaxReturnTypes || {});
