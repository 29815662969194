import { SubmissionPeriodStatusChange } from "./SubmissionPeriodStatusChange";
export class Refused extends SubmissionPeriodStatusChange {
  constructor(_data) {
    super();
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof Refused
     */
    this.$type = "V1.Submission.Refused";
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    super.init(_data);
    if (_data) {
      if (_data["AttachmentReferences"] && _data["AttachmentReferences"].constructor === Array) {
        this.AttachmentReferences = [];
        for (let item of _data["AttachmentReferences"]) {
          this.AttachmentReferences.push(item);
        }
      }
      this.Comment = _data["Comment"];
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    super.toJSON(_data);
    if (this.AttachmentReferences && this.AttachmentReferences.constructor === Array) {
      _data["AttachmentReferences"] = [];
      for (let item of this.AttachmentReferences) {
        _data["AttachmentReferences"].push(item);
      }
    }
    _data["Comment"] = this.Comment;
    return _data;
  }
}
