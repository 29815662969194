import "../../../assets/css/bundle.scss";
import "../../../assets/css/web/webbundle.scss";
import routesJson from "../../../../Infrastructure/Services/WonderWoman.WebHost/routes.json";
import "./globals";
import "./components";
import * as Router from "GlobalShared/router";
import { ReduxContext } from "GlobalShared/ReduxContext";
import "WebComponents/reducers";
ReduxContext.init();
Router.startRouting(routesJson, (route) => route.area === "SPAMain" || route.area === "SPAAdminRestricted");
