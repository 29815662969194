import { useEffect, useState } from "GlobalShared/haunted/CustomHooks";
import i18next from "i18next";
import { html } from "lit-html";
import { currentLocale, getLocalizedUrl } from "../globals";
import { unsafeHTML } from "lit-html/directives/unsafe-html";
import { validatePassword } from "GlobalShared/validators/password-validator";
import { validateEmail } from "GlobalShared/validators/email-validator";
import { footerLinksTemplate, footerTextTemplate, headerTemplate } from "GlobalShared/templates/commons";
export const observedAttributes = ["email", "error", "area", "type"];
export const name = "ww-register";
export const Component = (host) => {
  var _a;
  const props = {
    email: host.email,
    error: host.error,
    area: host.area,
    type: host.type
  };
  const validateEmailInternal = () => {
    return email && !validateEmail(email) ? i18next.t("Please provide a valid e-mail address.") : void 0;
  };
  const validatePasswordInternal = () => {
    return password && !validatePassword(password) ? i18next.t(
      "Password must be at least 8 digits long and contain any three of the following four qualities: Uppercase Characters, Lowercase Characters, Alphanumeric Characters, Special Characters."
    ) : void 0;
  };
  const generateError = () => {
    var _a2, _b;
    return (_b = (_a2 = validateEmailInternal()) != null ? _a2 : validatePasswordInternal()) != null ? _b : error;
  };
  const hasError = () => {
    return !email || !password || generateError() !== void 0;
  };
  const [error, setError] = useState(void 0);
  const [email, setEmail] = useState(props.email);
  const [password, setPassword] = useState(void 0);
  useEffect(() => {
    setError(void 0);
  }, [email, password]);
  useEffect(() => {
    setError(props.error);
  }, [props.error]);
  return html` <div class="min-h-screen m-auto relative sm:pb-20">
            ${headerTemplate()}
			${html` <div class="bg-white bg-opacity-75 text-black rounded px-8 pb-4 m-auto flex flex-col max-w-sm mb-16 mt-10">
				<div class="text-xl md:text-3xl font-bold mt-8 mb-6 text-center">${i18next.t("Create your account")}</div>
				<div class="mb-4">
					<dc-input
						.label=${i18next.t("Email")}
						.name=${"Email"}
						.value=${email}
						.placeholder=${i18next.t("Email")}
						.autocomplete=${"off"}
						@change=${(e) => setEmail(e.detail.value)}
					></dc-input>
				</div>
				<div class="mb-4">
					<dc-input
						.label=${i18next.t("Password")}
						.type=${"password"}
						.name=${"Password"}
						.value=${password}
						.placeholder=${"*************"}
						.autocomplete=${"off"}
						@change=${(e) => setPassword(e.detail.value)}
					></dc-input>
				</div>
				<input type="hidden" value=${currentLocale} name=${"locale"} />
				${props.area !== void 0 ? html`<input type="hidden" value=${props.area} name=${"area"} />` : ""}
				${props.type !== void 0 ? html`<input type="hidden" value=${props.type} name=${"type"} />` : ""}
				${hasError() ? html`<div class="mb-2">
							<label class="text-red-400"> ${generateError()} </label>
					  </div>` : ""}
				<div class="text-sm mb-4">
					${unsafeHTML(
    i18next.t(
      "login_disclaimer",
      "By signing in or creating an account, you accept our {{- link1_start}}General Terms of Business{{- link1_end}} and {{- link2_start}}Privacy Notice{{- link2_end}}.",
      {
        link1_start: '<a class="underline" href="/files/General_Terms_of_Business.pdf" target="_blank" rel="noopener" download="General_Terms_of_Business.pdf">',
        link1_end: "</a>",
        link2_start: '<a class="underline" href="https://home.kpmg/content/dam/kpmg/hu/pdf/KGSH_Privacy_Statement_v2.pdf" target="_blank" rel="noopener" download="Privacy_Statament.pdf">',
        link2_end: "</a>"
      }
    )
  )}
				</div>
				<div class="w-full">
					<button ?disabled=${hasError()} class="btn btn-primary btn-full" type="submit">${i18next.t("Create Account")}</button>
				</div>
				<div class="mt-4 text-center mb-12">
					<span class="text-sm">${i18next.t("You already have an account? Please")}</span>
					<a class="font-semibold underline text-sm" href=${getLocalizedUrl(`/account/login/${(_a = props.area) != null ? _a : ""}`)} data-routing="server">${i18next.t("sign in")}</a>
				</div>
			</div>`}
			<div class="w-full mt-4 sm:absolute inset-x-0 bottom-0 pb-4 px-2">
                ${footerTextTemplate()}
                ${footerLinksTemplate()}
			</div>
		</div>
	</div>`;
};
