import { footerLinksTemplate, footerTextTemplate, headerTemplate } from "./../../shared/templates/commons";
import { getParameter } from "GlobalShared/router";
import { useState } from "haunted";
import i18next from "i18next";
import { html } from "lit-html";
import { unsafeHTML } from "lit-html/directives/unsafe-html";
import { validateEmail } from "GlobalShared/validators/email-validator";
export const observedAttributes = ["error", "area"];
export const name = "ww-forgot-password";
export const Component = (host) => {
  const props = {
    error: host.error,
    area: host.area
  };
  const generateError = () => {
    if (props.error) {
      switch (props.error) {
        case "Invalid email.":
          return i18next.t("It looks like there is no account with this email address.");
        case "Account is not confirmed yet.":
          return i18next.t("Please confirm your email address.");
        default:
          return props.error;
      }
    }
    if (email && !validateEmail(email)) {
      return i18next.t("Please provide a valid e-mail address.");
    }
    return void 0;
  };
  const hasError = () => {
    return generateError() === void 0 ? false : true;
  };
  const [email, setEmail] = useState(void 0);
  return html` <div class="min-h-screen m-auto relative sm:pb-32">
		${getParameter("success") !== void 0 ? html`${headerTemplate()}
					<div class="md:text-xl font-bold my-8 lg:my-8 text-center max-w-screen-md m-auto">${i18next.t("Password reset email sent. Please check your inbox.")}</div>
					<div class="md:text-xl font-bold my-8 lg:my-8 text-center max-w-screen-md m-auto">
						${unsafeHTML(
    i18next.t("forgot_pw_resend", "If you haven't received the reset e-mail, please click {{- link_start}}here{{- link_end}} to resend or contact us at {{- contactEmail}}", {
      link_start: '<button class="underline font-bold" type="submit">',
      contactEmail: ClientContext.ContactEmailAddress,
      link_end: "</button>"
    })
  )}
					</div>` : html`${headerTemplate()}
					<div class="bg-white bg-opacity-75 text-black rounded px-8 py-6 m-auto flex flex-col max-w-sm">
						<div class="text-3xl font-bold mt-6 mb-8">${i18next.t("Password Recovery")}</div>
						<div class="mb-8">
							<dc-input
								.label=${i18next.t("Email")}
								.name=${"Email"}
								.value=${email}
								.placeholder=${i18next.t("Email")}
								@change=${(e) => setEmail(e.detail.value)}
							></dc-input>
						</div>
						${props.area !== void 0 ? html`<input type="hidden" value=${props.area} name=${"area"} />` : ""}
						${hasError() ? html`<div class="mb-6">
									<label class="text-red-400"> ${generateError()} </label>
							  </div>` : ""}
						<div class="w-full">
							<button class="btn btn-primary btn-full" type="submit">${i18next.t("Reset Password")}</button>
						</div>
					</div>
					<div class="w-full mt-4 sm:absolute inset-x-0 bottom-0 pb-4 px-2">${footerTextTemplate()} ${footerLinksTemplate()}</div>`}
	</div>`;
};
