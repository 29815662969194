var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { html } from "lit-html";
import { useEffect, useState, useValidationContext } from "GlobalShared/haunted/CustomHooks";
import i18next from "i18next";
import { MOVING_REASON_DATA_SOURCE, REASON_DATA_SOURCE } from "./NotificationModels";
import { ExpiredSubscriptionAllFields, ExpiredSubscriptionValidator } from "./NotificationValidator";
export const observedAttributes = [];
export const useShadowDOM = false;
export const name = "notification-feedback";
export const Component = (host) => {
  const props = {
    expiredSubscription: host.expiredSubscription,
    save: host.save,
    cancel: host.cancel
  };
  const [expiredSubscription, setExpiredSubscription] = useState(props.expiredSubscription);
  const validationContext = useValidationContext(new ExpiredSubscriptionValidator());
  const [dispatchIfValid, setDispatchIfValid] = useState(false);
  useEffect(() => {
    if (props.expiredSubscription) {
      validationContext.clearFieldValidations();
      setExpiredSubscription(props.expiredSubscription);
    }
  }, [props.expiredSubscription]);
  useEffect(() => __async(void 0, null, function* () {
    if (expiredSubscription && dispatchIfValid) {
      setDispatchIfValid(false);
      if (validationContext.validationResult.isValid()) {
        yield props.save(expiredSubscription);
      }
    }
  }), [expiredSubscription]);
  useEffect(() => {
    validationContext.validate(expiredSubscription);
  }, [expiredSubscription]);
  const htmlMovingReasonToggle = (movingReason) => {
    return html`<div
			@click=${() => {
      setExpiredSubscription(__spreadProps(__spreadValues({}, expiredSubscription), {
        movingReasons: expiredSubscription.movingReasons.some((mr) => mr === movingReason) ? expiredSubscription.movingReasons.filter((mr) => mr !== movingReason) : expiredSubscription.movingReasons.concat([movingReason])
      }));
    }}
			class="btn-toggle ${expiredSubscription.movingReasons.some((mr) => mr === movingReason) ? "selected" : ""}"
		>
			${MOVING_REASON_DATA_SOURCE.find((elem) => elem.movingReason === movingReason).label}
		</div>`;
  };
  return html`<div class="mt-2 xl:w-1/2 space-y-4">
		<div>
			<dc-select
				.label=${i18next.t("Why you don't want to renew?")}
				.dataSource=${REASON_DATA_SOURCE.map((elem) => ({
    value: elem.reason,
    label: elem.label
  }))}
				.selectedValues=${expiredSubscription.reason}
				.validationMessage=${validationContext.getValidationMessage("reason")}
				@change=${(e) => {
    const newVal = e.detail.selectedValue;
    validationContext.validateField("reason");
    setExpiredSubscription(__spreadProps(__spreadValues({}, expiredSubscription), {
      reason: newVal
    }));
  }}
			></dc-select>
		</div>
		${expiredSubscription.reason === "Deregistration" ? html`<div class="italic">
					${i18next.t(
    "Please consult your account manager about the de-registration process and fees. Please note that you may be required to extend the services and continue submitting VAT returns until the de-registration is completed."
  )}
			  </div>` : ""}
		${expiredSubscription.reason === "Moving" ? html`<div class="space-y-4">
					<div class="flex space-x-4">
						${MOVING_REASON_DATA_SOURCE.map((elem) => {
    return htmlMovingReasonToggle(elem.movingReason);
  })}
					</div>
					${expiredSubscription.movingReasons.some((mr) => mr === "Other") ? html`<div>
								<dc-input
									.placeholder=${i18next.t("Please enter text")}
									.value=${expiredSubscription.otherReasonText}
									.validationMessage=${validationContext.getValidationMessage("otherReasonText")}
									@change=${(e) => {
    const newVal = e.detail.value;
    validationContext.validateField("otherReasonText");
    setExpiredSubscription(__spreadProps(__spreadValues({}, expiredSubscription), {
      otherReasonText: newVal
    }));
  }}
								></dc-input>
						  </div>` : ""}
					${validationContext.getValidationMessage("movingReason") ? html` <div class="text-red-500 text-xs">${validationContext.getValidationMessage("movingReason")}</div> ` : ""}
			  </div>` : ""}

		<div>
			<div class="flex space-x-4">
				<button
					class="btn btn-primary"
					@click=${() => {
    props.cancel();
  }}
				>
					${i18next.t("Cancel")}
				</button>
				<button
					class="btn btn-primary"
					@click=${() => {
    validationContext.validateFields(ExpiredSubscriptionAllFields);
    setExpiredSubscription(__spreadValues({}, expiredSubscription));
    setDispatchIfValid(true);
  }}
				>
					${i18next.t("Save")}
				</button>
			</div>
		</div>
	</div>`;
};
