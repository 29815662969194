import { footerLinksTemplate, footerTextTemplate, headerTemplate } from "../../shared/templates/commons";
import i18next from "i18next";
import { html } from "lit-html";
import { getLocalizedUrl } from "../globals";
export const observedAttributes = ["error"];
export const name = "ww-email-verify";
export const Component = (host) => {
  const props = {
    error: host.error
  };
  return html`<div class="min-h-screen m-auto relative sm:pb-20">
		${headerTemplate()}

		<div class="bg-white bg-opacity-75 text-black rounded p-8 font-semibold m-auto flex flex-col mb-16">
			${props.error ? `${i18next.t("An error has occured during email verification.")} (${props.error})` : html` ${i18next.t("Your email address has been successfully changed, please log out and login with your new email address to take it into effect.")}
						<a class="font-semibold underline" href=${getLocalizedUrl(`/account/login`)} data-routing="server">${i18next.t("Sign in to your account")}</a>`}
		</div>
		<div class="w-full mt-4 sm:absolute inset-x-0 bottom-0 pb-4 px-2">${footerTextTemplate()} ${footerLinksTemplate()}</div>
	</div>`;
};
