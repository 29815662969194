import { LeavingReason } from "./LeavingReason";
import { DeregistrationReason } from "./DeregistrationReason";
import { MovingReason } from "./MovingReason";
export class LeavingReasonFactory {
  static create($type) {
    if ($type === "V1.Companies.Subscriptions.Feedbacks.LeavingReason") {
      const result = new LeavingReason();
      return result;
    }
    if ($type === "V1.Companies.Subscriptions.Feedbacks.DeregistrationReason") {
      const result = new DeregistrationReason();
      return result;
    }
    if ($type === "V1.Companies.Subscriptions.Feedbacks.MovingReason") {
      const result = new MovingReason();
      return result;
    }
    throw new Error($type + " not found");
  }
}
