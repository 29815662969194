import { AbstractValidator } from "fluent-ts-validator";
import { DCValidationResult } from "GlobalShared/haunted/HooksHelpers";
import { REQUIRED_MESSAGE } from "GlobalShared/helpers/stringHelper";
import i18next from "i18next";
export const ExpiredSubscriptionAllFields = ["reason", "movingReason", "otherReasonText"];
export class ExpiredSubscriptionValidator extends AbstractValidator {
  constructor() {
    super();
    this.validateIf((s) => s.reason).isDefined().withPropertyName("reason").withFailureMessage(REQUIRED_MESSAGE);
    this.validateIfNumber((s) => s.movingReasons.length).isGreaterThan(0).withPropertyName("movingReason").withFailureMessage(i18next.t("Please select at least one reason")).when((s) => s.reason === "Moving");
    this.validateIf((s) => s.otherReasonText).isDefined().withPropertyName("otherReasonText").withFailureMessage(REQUIRED_MESSAGE).when((s) => s.movingReasons.some((mr) => mr === "Other"));
  }
  validateModel(item) {
    return new DCValidationResult(super.validate(item));
  }
}
