export class Service {
  constructor(_data) {
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof Service
     */
    this.$type = "V1.Orders.Create.Request.Service";
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      this.Code = ServiceCode[_data["Code"]];
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    _data["$type"] = this.$type;
    _data["Code"] = ServiceCode[this.Code];
    return _data;
  }
}
export var ServiceCode = /* @__PURE__ */ ((ServiceCode2) => {
  ServiceCode2["VatCompliance"] = "VatCompliance";
  ServiceCode2["VatMonthlyCompliance"] = "VatMonthlyCompliance";
  ServiceCode2["VatHomeCountryCompliance"] = "VatHomeCountryCompliance";
  ServiceCode2["VatRegistration"] = "VatRegistration";
  ServiceCode2["VatTakeOver"] = "VatTakeOver";
  ServiceCode2["VatSpanishBankAccount"] = "VatSpanishBankAccount";
  ServiceCode2["VatFiscalRepresentative"] = "VatFiscalRepresentative";
  ServiceCode2["VatFilingAgent"] = "VatFilingAgent";
  ServiceCode2["VatRetrospectiveReturn"] = "VatRetrospectiveReturn";
  ServiceCode2["VatIntrastatIn"] = "VatIntrastatIn";
  ServiceCode2["VatIntrastatOut"] = "VatIntrastatOut";
  ServiceCode2["VatDeregistration"] = "VatDeregistration";
  ServiceCode2["VatSelfRevision"] = "VatSelfRevision";
  ServiceCode2["IossComplianceTier0"] = "IossComplianceTier0";
  ServiceCode2["IossComplianceTier1"] = "IossComplianceTier1";
  ServiceCode2["IossComplianceTier2"] = "IossComplianceTier2";
  ServiceCode2["IossComplianceTier3"] = "IossComplianceTier3";
  ServiceCode2["IossRegistration"] = "IossRegistration";
  ServiceCode2["IossTakeOver"] = "IossTakeOver";
  ServiceCode2["IossDeregistration"] = "IossDeregistration";
  ServiceCode2["OssCompliance"] = "OssCompliance";
  ServiceCode2["OssRegistration"] = "OssRegistration";
  ServiceCode2["OssTakeOver"] = "OssTakeOver";
  ServiceCode2["OssDeregistration"] = "OssDeregistration";
  ServiceCode2["GlobalEoriUk"] = "GlobalEoriUk";
  ServiceCode2["GlobalEoriEu"] = "GlobalEoriEu";
  ServiceCode2["GlobalGeneralVatAdvice"] = "GlobalGeneralVatAdvice";
  ServiceCode2["GlobalSpecialistVatAdvice"] = "GlobalSpecialistVatAdvice";
  ServiceCode2["GlobalCommunicatingWithTaxAuthorities"] = "GlobalCommunicatingWithTaxAuthorities";
  ServiceCode2["GlobalChangeCompanyDetail"] = "GlobalChangeCompanyDetail";
  ServiceCode2["GlobalContactingTaxAuthorities"] = "GlobalContactingTaxAuthorities";
  ServiceCode2["GlobalTranslation"] = "GlobalTranslation";
  ServiceCode2["GlobalTaxCertificate"] = "GlobalTaxCertificate";
  ServiceCode2["GlobalIntrastatThresholdCheck"] = "GlobalIntrastatThresholdCheck";
  ServiceCode2["GlobalDistanceSaleThresholdCheck"] = "GlobalDistanceSaleThresholdCheck";
  return ServiceCode2;
})(ServiceCode || {});
