var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { useOrderServices } from "GlobalShared/components/orders/services/order-services";
import { createPriceConfig, getFeeSummary } from "GlobalShared/components/orders/services/order-services-utils";
import { PRICE_CONFIG_MOCK } from "GlobalShared/components/orders/services/OrderServiceModels";
import { useEffect, useLoadingReducer, useState } from "GlobalShared/haunted/CustomHooks";
import i18next from "i18next";
import { html } from "lit-html";
import { useEnglishWorldCountries, useServiceCountries } from "WebComponents/hooks/WebCustomHooks";
import { priceBreakdownTemplate } from "GlobalShared/components/orders/order-price-breakdown";
import { useOrderSummary } from "GlobalShared/components/orders/order-summary";
import { useCommonCalls } from "./admin-common-calls";
import { generatePDFContract } from "GlobalShared/components/orders/contract/pdf-generator";
import { DEFAULT_CURRENCY_CODE } from "GlobalShared/components/orders/OrderModels";
import { notifyError, notifySuccess, withErrorHandling } from "GlobalShared/helpers/errorHelper";
import { currentLocale } from "../../globals";
import { mapToCreatedServices } from "GlobalShared/components/orders/services/CreateServiceModels";
import { getCompanyName } from "GlobalShared/helpers/servicesHelper";
import { useConfirmModal } from "GlobalShared/components/modals/commonModals";
const DEV_MODE = ClientContext.FeatureFlags.DevMode;
const SERVICES_PROGRESS_ITEMS = [i18next.t("Service Selection"), i18next.t("Additional Services"), i18next.t("Summary")];
const SERVICE_STEPS = {
  SELECTOR: 0,
  ADDITIONAL: 1,
  SUMMARY: 2
};
export const useManageServices = (props) => {
  var _a, _b;
  const createOrder = () => __async(void 0, null, function* () {
    return withErrorHandling(
      () => __async(void 0, null, function* () {
        var _a2;
        const orderResult = yield commonCalls.createOrder((_a2 = props.currentCompanyServices) == null ? void 0 : _a2.Id, mapToCreatedServices(true, /* @__PURE__ */ new Date(), priceConfig, servicesHook.state));
        if (orderResult) {
          setCreatedOrderModel(__spreadProps(__spreadValues({}, createdOrderModel), {
            Id: orderResult.Id,
            ContractId: orderResult.ContractId,
            ClientSource: orderResult.ClientSource,
            OrderSummary: orderResult.OrderSummary
          }));
          notifySuccess("Order successfully saved");
        }
      }),
      (error) => {
        notifyError(error);
      }
    );
  });
  const confirmModal = useConfirmModal();
  const serviceCountries = useServiceCountries(currentLocale);
  const englishWorldCountries = useEnglishWorldCountries();
  const [createdOrderModel, setCreatedOrderModel] = useState({});
  const [priceConfig, setPriceConfig] = useState(void 0);
  const [loadingServices, dispatchLoadingServices] = useLoadingReducer();
  const [feeSummary, setFeeSummary] = useState(void 0);
  const [currentStep, setCurrentStep] = useState(0);
  const [nextInProgress, setNextInProgress] = useState(false);
  const commonCalls = useCommonCalls();
  const servicesHook = useOrderServices({
    devMode: false,
    isAdmin: false,
    isSignup: false,
    countryOfEstablishment: (_a = props.currentCompanyServices) == null ? void 0 : _a.EstablishmentCountryCode,
    servicesFlowType: "AddServices",
    euCountries: props.euCountries,
    worldCountries: props.worldCountries,
    serviceCountries,
    priceConfig,
    priceBreakdownTemplate: () => priceBreakdownTemplate(feeSummary),
    currentCompanyServices: props.currentCompanyServices,
    loadingServices
  });
  const orderSummaryHook = useOrderSummary({
    isAdmin: false,
    priceBreakdownTemplate: () => priceBreakdownTemplate(feeSummary),
    isParentValid: servicesHook.isValid,
    order: createdOrderModel,
    hasContractReference: false,
    isSignup: false,
    companyId: (_b = props.currentCompanyServices) == null ? void 0 : _b.Id,
    generateContractPdf: () => {
      var _a2;
      const pdfModel = {
        partnerName: getCompanyName(
          props.currentCompanyServices.CompanyDetails.legalStatus,
          props.currentCompanyServices.CompanyDetails.companyName,
          props.currentCompanyServices.CompanyDetails.contactFirstName,
          props.currentCompanyServices.CompanyDetails.contactLastName
        ),
        legalStatus: props.currentCompanyServices.CompanyDetails.legalStatus,
        addressLine: `${props.currentCompanyServices.CompanyDetails.addressStreet} ${props.currentCompanyServices.CompanyDetails.addressStreetNo}, ${props.currentCompanyServices.CompanyDetails.addressZip} ${props.currentCompanyServices.CompanyDetails.addressCity} ${englishWorldCountries.find((wc) => wc.Code === props.currentCompanyServices.CompanyDetails.countryOfEstablishment).Name}`,
        summary: __spreadProps(__spreadValues({}, feeSummary), {
          countries: feeSummary.countries.map((sc) => __spreadProps(__spreadValues({}, sc), {
            label: englishWorldCountries.find((wc) => wc.Code === sc.countryCode).Name
          }))
        }),
        contractDate: /* @__PURE__ */ new Date(),
        iossTier: (_a2 = servicesHook.state.iossSelection) == null ? void 0 : _a2.tier,
        vatNumber: props.currentCompanyServices.CompanyDetails.vatNumber ? props.currentCompanyServices.CompanyDetails.countryOfEstablishment + props.currentCompanyServices.CompanyDetails.vatNumber : "",
        kpmgReference: createdOrderModel == null ? void 0 : createdOrderModel.ContractId,
        hasVatService: servicesHook.state.vatCountrySelection.length > 0,
        clientSource: createdOrderModel == null ? void 0 : createdOrderModel.ClientSource
      };
      return generatePDFContract(pdfModel);
    },
    getPaymentInfo: commonCalls.getPaymentInfo,
    payAndConfirm: commonCalls.payAndConfirm,
    uploadContract: commonCalls.uploadContract,
    downloadContract: () => {
      return void 0;
    }
  });
  useEffect(() => __async(void 0, null, function* () {
    var _a2;
    if (((_a2 = props.currentCompanyServices) == null ? void 0 : _a2.Id) && servicesHook.state.vatCountrySelection !== void 0 && props.isManageMode) {
      if (DEV_MODE && PRICE_CONFIG_MOCK) {
        setPriceConfig(PRICE_CONFIG_MOCK);
      } else {
        dispatchLoadingServices("inc");
        try {
          const availableServices = yield commonCalls.getAvailableServices(DEFAULT_CURRENCY_CODE, servicesHook.state.vatCountrySelection, servicesHook.state.iossSelection);
          setPriceConfig(createPriceConfig(availableServices));
        } finally {
          dispatchLoadingServices("dec");
        }
      }
    }
  }), [props.currentCompanyServices, servicesHook.state.iossSelection, servicesHook.state.vatCountrySelection]);
  useEffect(() => {
    var _a2, _b2, _c;
    if (priceConfig && props.currentCompanyServices && servicesHook.state && props.euCountries && serviceCountries && props.isManageMode) {
      const tempFeeSummary = getFeeSummary(
        false,
        /* @__PURE__ */ new Date(),
        priceConfig,
        (_a2 = props.currentCompanyServices) == null ? void 0 : _a2.EstablishmentCountryCode,
        (_c = (_b2 = props.currentCompanyServices) == null ? void 0 : _b2.IsVatNumberValid) != null ? _c : false,
        servicesHook.state,
        props.euCountries,
        serviceCountries
      );
      setFeeSummary(tempFeeSummary);
    }
  }, [priceConfig, props.currentCompanyServices, servicesHook.state, props.euCountries, serviceCountries]);
  const htmlMain = () => {
    if (props.canCreateOrder === void 0) {
      return `${i18next.t("Loading")}...`;
    }
    if (!props.canCreateOrder) {
      return i18next.t("You have one pending order in progress");
    }
    return html`<div class="flex justify-around w-full my-4">
				<dc-step-progressbar
					.items=${SERVICES_PROGRESS_ITEMS}
					.activeIndex=${currentStep}
					@click=${(e) => {
      if (e.detail.step < currentStep) {
        setCurrentStep(e.detail.step);
      }
    }}
				></dc-step-progressbar>
			</div>
			${currentStep === 0 ? servicesHook.htmlMainSelector() : ""} ${currentStep === 1 ? servicesHook.htmlAdditionalServices() : ""} ${currentStep === 2 ? orderSummaryHook.mainTemplate() : ""}
			<div class="mt-12 flex w-full justify-between">
				<button
					class="px-4 h-12 border-2 border-brand-primary-light rounded text-brand-primary text-sm bg-white font-semibold ${currentStep === 0 ? "invisible" : ""}"
					@click="${() => {
      if (currentStep === SERVICE_STEPS.SUMMARY) {
        setCurrentStep(SERVICE_STEPS.ADDITIONAL);
      } else if (currentStep === SERVICE_STEPS.ADDITIONAL) {
        setCurrentStep(SERVICE_STEPS.SELECTOR);
      }
    }}}"
				>
					${i18next.t("Previous")}
				</button>
				<button
					class="btn btn-primary h-12 ${currentStep === SERVICE_STEPS.SUMMARY ? "invisible" : ""}"
					?disabled=${currentStep === SERVICE_STEPS.SELECTOR && !servicesHook.isValidMainSelector || currentStep === SERVICE_STEPS.ADDITIONAL && !servicesHook.isValid || nextInProgress || loadingServices.count > 0}
					@click=${() => __async(void 0, null, function* () {
      if (currentStep === SERVICE_STEPS.SELECTOR) {
        if (servicesHook.isValidMainSelector) {
          setCurrentStep(SERVICE_STEPS.ADDITIONAL);
        }
      } else if (currentStep === SERVICE_STEPS.ADDITIONAL) {
        if (servicesHook.isValid) {
          try {
            const hasRestart = servicesHook.state.vatServices.some((v) => v.restartCompliance);
            if (!hasRestart || (yield confirmModal.confirm(
              i18next.t(
                "order-compliance-restart-warning",
                "Warning! This will remove any previously saved settings from your account. If you wanted to renew an expiring subscription, please select NO and select 'Renew for one year' instead."
              )
            ))) {
              setNextInProgress(true);
              yield createOrder();
              setCurrentStep(SERVICE_STEPS.SUMMARY);
            }
          } finally {
            setNextInProgress(false);
          }
        }
      }
    })}
				>
					${nextInProgress ? `${i18next.t("Loading")}...` : i18next.t("Next")}
				</button>
			</div>
			${confirmModal.mainTemplate()}`;
  };
  return {
    htmlMain
  };
};
