import { ServiceFactory } from "./ServiceFactory";
export class CreateOrderRequest {
  constructor(_data) {
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof CreateOrderRequest
     */
    this.$type = "V1.Orders.Create.Request.CreateOrderRequest";
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      this.Currency = _data["Currency"];
      this.FlowType = CreateOrderRequestFlowType[_data["FlowType"]];
      if (_data["Services"] && _data["Services"].constructor === Array) {
        this.Services = [];
        for (let item of _data["Services"]) {
          const $typeServices = item && item.$type;
          if ($typeServices) {
            const services_ = ServiceFactory.create($typeServices);
            this.Services.push(services_.init(item));
          }
        }
      }
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    _data["Currency"] = this.Currency;
    _data["FlowType"] = CreateOrderRequestFlowType[this.FlowType];
    if (this.Services && this.Services.constructor === Array) {
      _data["Services"] = [];
      for (let item of this.Services) {
        _data["Services"].push(item.toJSON());
      }
    }
    return _data;
  }
}
export var CreateOrderRequestFlowType = /* @__PURE__ */ ((CreateOrderRequestFlowType2) => {
  CreateOrderRequestFlowType2["SignUp"] = "SignUp";
  CreateOrderRequestFlowType2["CreateOrder"] = "CreateOrder";
  return CreateOrderRequestFlowType2;
})(CreateOrderRequestFlowType || {});
