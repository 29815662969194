import { File } from "./File";
export class SubmissionPeriodFiles {
  constructor(_data) {
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof SubmissionPeriodFiles
     */
    this.$type = "V1.Submission.SubmissionPeriodFiles";
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      if (_data["DataVatFiles"] && _data["DataVatFiles"].constructor === Array) {
        this.DataVatFiles = [];
        for (let item of _data["DataVatFiles"]) {
          const dataVatFiles_ = new File();
          this.DataVatFiles.push(dataVatFiles_.init(item));
        }
      }
      if (_data["InvalidVatNumbersReportFiles"] && _data["InvalidVatNumbersReportFiles"].constructor === Array) {
        this.InvalidVatNumbersReportFiles = [];
        for (let item of _data["InvalidVatNumbersReportFiles"]) {
          const invalidVatNumbersReportFiles_ = new File();
          this.InvalidVatNumbersReportFiles.push(invalidVatNumbersReportFiles_.init(item));
        }
      }
      if (_data["NotToBeSubmittedProof"]) {
        const notToBeSubmittedProof_ = new File();
        this.NotToBeSubmittedProof = notToBeSubmittedProof_.init(_data["NotToBeSubmittedProof"]);
      }
      if (_data["OutOfScopeFiles"] && _data["OutOfScopeFiles"].constructor === Array) {
        this.OutOfScopeFiles = [];
        for (let item of _data["OutOfScopeFiles"]) {
          const outOfScopeFiles_ = new File();
          this.OutOfScopeFiles.push(outOfScopeFiles_.init(item));
        }
      }
      if (_data["ReturnFiles"] && _data["ReturnFiles"].constructor === Array) {
        this.ReturnFiles = [];
        for (let item of _data["ReturnFiles"]) {
          const returnFiles_ = new File();
          this.ReturnFiles.push(returnFiles_.init(item));
        }
      }
      if (_data["TransactionFiles"] && _data["TransactionFiles"].constructor === Array) {
        this.TransactionFiles = [];
        for (let item of _data["TransactionFiles"]) {
          const transactionFiles_ = new File();
          this.TransactionFiles.push(transactionFiles_.init(item));
        }
      }
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    if (this.DataVatFiles && this.DataVatFiles.constructor === Array) {
      _data["DataVatFiles"] = [];
      for (let item of this.DataVatFiles) {
        _data["DataVatFiles"].push(item.toJSON());
      }
    }
    if (this.InvalidVatNumbersReportFiles && this.InvalidVatNumbersReportFiles.constructor === Array) {
      _data["InvalidVatNumbersReportFiles"] = [];
      for (let item of this.InvalidVatNumbersReportFiles) {
        _data["InvalidVatNumbersReportFiles"].push(item.toJSON());
      }
    }
    _data["NotToBeSubmittedProof"] = this.NotToBeSubmittedProof ? this.NotToBeSubmittedProof.toJSON() : void 0;
    if (this.OutOfScopeFiles && this.OutOfScopeFiles.constructor === Array) {
      _data["OutOfScopeFiles"] = [];
      for (let item of this.OutOfScopeFiles) {
        _data["OutOfScopeFiles"].push(item.toJSON());
      }
    }
    if (this.ReturnFiles && this.ReturnFiles.constructor === Array) {
      _data["ReturnFiles"] = [];
      for (let item of this.ReturnFiles) {
        _data["ReturnFiles"].push(item.toJSON());
      }
    }
    if (this.TransactionFiles && this.TransactionFiles.constructor === Array) {
      _data["TransactionFiles"] = [];
      for (let item of this.TransactionFiles) {
        _data["TransactionFiles"].push(item.toJSON());
      }
    }
    return _data;
  }
}
