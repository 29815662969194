import { footerLinksTemplate, footerTextTemplate, headerTemplate } from "./../../shared/templates/commons";
import { useState } from "GlobalShared/haunted/CustomHooks";
import i18next from "i18next";
import { html } from "lit-html";
import { getLocalizedUrl } from "../globals";
import { unsafeHTML } from "lit-html/directives/unsafe-html";
import { validateEmail } from "GlobalShared/validators/email-validator";
import { getParameter } from "GlobalShared/router";
import { useGoogleReCaptcha } from "GlobalShared/reCaptcha/useGoogleReCaptcha";
export const observedAttributes = ["email", "error", "area"];
export const name = "ww-login";
export const DEFAULT_LOCKOUT_TIME_IN_MINUTES = 15;
export const Component = (host) => {
  var _a, _b;
  const props = {
    email: host.email,
    error: host.error,
    area: host.area
  };
  const generateError = () => {
    if (props.error) {
      switch (props.error) {
        case "User account locked out.":
          return i18next.t("Your account is locked out for {{min}} minutes. Please contact our support team.", { min: DEFAULT_LOCKOUT_TIME_IN_MINUTES });
        case "Invalid login attempt.":
          return i18next.t("The e-mail address and password do not match.");
        default:
          return props.error;
      }
    }
    if (email && !validateEmail(email)) {
      return i18next.t("Please provide a valid e-mail address.");
    }
    return void 0;
  };
  const hasError = () => {
    return !email || !password || generateError() !== void 0 ? true : false;
  };
  const [email, setEmail] = useState(props.email);
  const [password, setPassword] = useState(void 0);
  const submitWithCaptcha = useGoogleReCaptcha("loginForm", "WebLogin");
  return html`<div class="min-h-screen m-auto relative sm:pb-20">
		${headerTemplate()}

		<div class="bg-white bg-opacity-75 text-black rounded px-8 py-4 m-auto flex flex-col max-w-sm mb-16">
			${getParameter("success") !== void 0 ? html`<div>
						<div class="text-lg text-green-500 font-bold text-center py-4">${`${i18next.t("You have successfully registered")}!`}</div>
				  </div>` : ""}
			<div class="text-3xl font-bold mt-4 lg:mt-6 mb-4 text-center">${i18next.t("Sign in to your account")}</div>
			<div class="mb-4">
				<dc-input .label=${i18next.t("Email")} .name=${"Email"} .value=${email} .placeholder=${i18next.t("Email")} @change=${(e) => setEmail(e.detail.value)}></dc-input>
			</div>
			<div class="mb-4">
				<dc-input
					.label=${i18next.t("Password")}
					.type=${"password"}
					.name=${"Password"}
					.value=${password}
					.placeholder=${"*************"}
					@change=${(e) => setPassword(e.detail.value)}
				></dc-input>
			</div>
			${hasError() ? html`<div class="mb-4">
						<label class="text-red-400"> ${generateError()} </label>
				  </div>` : ""}
			<div class="text-sm mb-4">
				${unsafeHTML(
    i18next.t(
      "login_disclaimer",
      "By signing in or creating an account, you accept our {{- link1_start}}General Terms of Business{{- link1_end}} and {{- link2_start}}Privacy Notice{{- link2_end}}.",
      {
        link1_start: '<a class="underline" href="/files/General_Terms_of_Business.pdf" target="_blank" rel="noopener" download="General_Terms_of_Business.pdf">',
        link1_end: "</a>",
        link2_start: '<a class="underline" href="https://home.kpmg/content/dam/kpmg/hu/pdf/KGSH_Privacy_Statement_v2.pdf" target="_blank" rel="noopener" download="Privacy_Statament.pdf">',
        link2_end: "</a>"
      }
    )
  )}
			</div>
			<div class="w-full">
				<button class="btn btn-primary btn-full" @click=${submitWithCaptcha}>${i18next.t("Sign in")}</button>
			</div>
			<div class="md:flex justify-between mt-4">
				<div class="text-center">
					<a class="underline text-sm" href=${getLocalizedUrl(`/account/forgotpassword/${(_a = props.area) != null ? _a : ""}`)} data-routing="server">${i18next.t("Forgot your password?")}</a>
				</div>
				<div class="mt-4 md:mt-0 text-center">
					<a class="underline text-sm" href=${getLocalizedUrl(`/Account/Register/${(_b = props.area) != null ? _b : ""}`)} data-routing="server">${i18next.t("Don't have account yet?")}</a>
				</div>
			</div>
		</div>
		<div class="w-full mt-4 sm:absolute inset-x-0 bottom-0 pb-4 px-2">${footerTextTemplate()} ${footerLinksTemplate()}</div>
	</div>`;
};
