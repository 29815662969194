import { webLanguages } from "GlobalShared/helpers/webLocaleHelper";
import { useEffect, useState } from "GlobalShared/haunted/CustomHooks";
import { html } from "lit-html";
import cookie from "js-cookie";
import { currentLanguage } from "../globals";
import i18next from "i18next";
import { getParameter } from "GlobalShared/router";
export const useShadowDOM = false;
export const name = "ww-language-select";
export const Component = (host) => {
  const flagUrl = (lang) => `/images/${lang}_flag.svg`;
  const [languageMenuOpen, setLanguageMenuOpen] = useState(false);
  const [showLanguageAlertModal, setShowLanguageAlertModal] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(void 0);
  const init = () => {
    const onClickedOutside = (e) => {
      if (!host.contains(e.target)) {
        setLanguageMenuOpen(false);
      }
    };
    const onCloseOnEscKey = (e) => {
      if (e.key === "Escape") {
        setLanguageMenuOpen(false);
      }
    };
    document.addEventListener("keyup", onCloseOnEscKey, true);
    document.addEventListener("click", onClickedOutside);
    return () => {
      document.removeEventListener("keyup", onCloseOnEscKey);
      document.removeEventListener("click", onClickedOutside);
    };
  };
  useEffect(init, []);
  const onToggleLanguageMenu = () => {
    setLanguageMenuOpen(!languageMenuOpen);
  };
  const changeLanguage = (lang) => {
    cookie.set("lang", `c=${lang.locale}|uic=${lang.locale}`, { expires: 365, secure: true });
    window.location.href = `/${lang.country}/${lang.lang}/${window.location.pathname.substring("/gb/en/".length)}${window.location.search}`;
  };
  const onChangeLanguage = (e, lang) => {
    e.preventDefault();
    e.stopPropagation();
    if (getParameter("lnglrt") === "true") {
      setShowLanguageAlertModal(true);
      setSelectedLanguage(lang);
    } else {
      changeLanguage(lang);
    }
  };
  const languageChangeAlertModalTemplate = () => html`
		<dc-modal
			@close=${() => setShowLanguageAlertModal(false)}
			.visible=${showLanguageAlertModal}
			.showHeader=${false}
			.customClass=${"language-alert"}
			.content=${html`
				<div class="lg:py-6">
					<div class="text-brand-primary text-base text-center mb-6">${i18next.t("Please note that any changes you made will be lost if you switch to another language.")}</div>
					<div class="language-alert-modal-btn-container">
						<button class="btn btn-secondary" @click=${() => setShowLanguageAlertModal(false)}>${i18next.t("Cancel")}</button>
						<button class="btn btn-primary" @click=${() => changeLanguage(selectedLanguage)}>${i18next.t("Change Language")}</button>
					</div>
				</div>
			`}
		></dc-modal>
	`;
  return html`<div class="ww-language-select-container">
		<div @click=${onToggleLanguageMenu} class="ww-language-select-dropdown-opener">
			<img src=${flagUrl(currentLanguage.lang)} />
			<span>${currentLanguage.name}</span>
			<div class="language-select-arrow ${languageMenuOpen ? "open" : ""}">&#10095;</div>
		</div>
		<div class="ww-language-select-dropdown ${languageMenuOpen ? "open" : ""}">
			${Object.keys(webLanguages).filter((key) => webLanguages[key].locale !== currentLanguage.locale).map((key) => {
    const lang = webLanguages[key];
    return html`
						<a @click=${(e) => onChangeLanguage(e, lang)} class="ww-language-select-dropdown-item">
							<img src=${flagUrl(lang.lang)} />
							<span>${lang.name}</span>
						</a>
					`;
  })}
		</div>
		${languageChangeAlertModalTemplate()}
	</div>`;
};
