import { SelectedService } from "./SelectedService";
export class GetServicesRequest {
  constructor(_data) {
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof GetServicesRequest
     */
    this.$type = "V1.Services.V2.GetServicesRequest";
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      this.CurrencyCode = _data["CurrencyCode"];
      if (_data["SelectedServices"] && _data["SelectedServices"].constructor === Array) {
        this.SelectedServices = [];
        for (let item of _data["SelectedServices"]) {
          const selectedServices_ = new SelectedService();
          this.SelectedServices.push(selectedServices_.init(item));
        }
      }
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    _data["CurrencyCode"] = this.CurrencyCode;
    if (this.SelectedServices && this.SelectedServices.constructor === Array) {
      _data["SelectedServices"] = [];
      for (let item of this.SelectedServices) {
        _data["SelectedServices"].push(item.toJSON());
      }
    }
    return _data;
  }
}
