import i18next from "i18next";
import { html } from "lit-html";
import { unsafeHTML } from "lit-html/directives/unsafe-html";
export function Contact() {
  return html`<div class="py-2 px-8 xl:mx-auto xl:w-1/2">
		<h1 class="text-2xl mt-12 mb-8 font-bold">${i18next.t("Contact")}</h1>
		<p class="mb-6 text-brand-primary font-bold">
			${unsafeHTML(
    i18next.t("contacts_message", "Specific questions: please write to {{- link_start}}{{- contactEmail}}{{- link_end}}.", {
      link_start: `<a class="underline" href="mailto:${ClientContext.ContactEmailAddress}">`,
      contactEmail: ClientContext.ContactEmailAddress,
      link_end: "</a>"
    })
  )}
		</p>
		<p class="mt-6 text-brand-primary font-bold">${i18next.t("We look forward to helping you.")}</p>
	</div>`;
}
