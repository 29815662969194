import { LeavingReasonFactory } from "./LeavingReasonFactory";
export class Feedback {
  constructor(_data) {
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof Feedback
     */
    this.$type = "V1.Companies.Subscriptions.Feedbacks.Feedback";
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      if (_data["LeavingReasons"] && _data["LeavingReasons"].constructor === Array) {
        this.LeavingReasons = [];
        for (let item of _data["LeavingReasons"]) {
          const $typeLeavingReasons = item && item.$type;
          if ($typeLeavingReasons) {
            const leavingReasons_ = LeavingReasonFactory.create($typeLeavingReasons);
            this.LeavingReasons.push(leavingReasons_.init(item));
          }
        }
      }
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    if (this.LeavingReasons && this.LeavingReasons.constructor === Array) {
      _data["LeavingReasons"] = [];
      for (let item of this.LeavingReasons) {
        _data["LeavingReasons"].push(item.toJSON());
      }
    }
    return _data;
  }
}
