import { footerLinksTemplate } from "GlobalShared/templates/commons";
import i18next from "i18next";
import { html } from "lit-html";
import moment from "moment";
import { getRouteByKey } from "../globals";
const FOOTER_LINKS = [
  { label: "Guides and Tutorials", key: "guides-and-tutorials" },
  { label: "FAQs", key: "faqs" },
  { label: "Legal", key: "legal" },
  { label: "Privacy", key: "privacy" },
  // { label: "Accessibility", key: "accessibility" },
  { label: "Contact", key: "contact" }
];
export function Footer() {
  return html`<footer class="block absolute bottom-0 w-full bg-white border-t border-brand-primary">
		<div class="sm:flex items-center justify-between text-brand-secondary py-4 px-2">
			<div class="text-brand-primary justify-start flex mb-2">
				<div>
					<a href="https://www.facebook.com/KPMG">
						<svg class="h-8 w-8 fill-current hover:text-brand-secondary mr-4" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
							<path d="M1343 12v264h-157q-86 0-116 36t-30 108v189h293l-39 296h-254v759h-306v-759h-255v-296h255v-218q0-186 104-288.5t277-102.5q147 0 228 12z" />
						</svg>
					</a>
				</div>
				<div>
					<a href="https://www.twitter.com/KPMG">
						<svg class="h-8 w-8 fill-current hover:text-brand-secondary mr-4" viewBox="0 0 1792 1792">
							<path
								d="M1684 408q-67 98-162 167 1 14 1 42 0 130-38 259.5t-115.5 248.5-184.5 210.5-258 146-323 54.5q-271 0-496-145 35 4 78 4 225 0 401-138-105-2-188-64.5t-114-159.5q33 5 61 5 43 0 85-11-112-23-185.5-111.5t-73.5-205.5v-4q68 38 146 41-66-44-105-115t-39-154q0-88 44-163 121 149 294.5 238.5t371.5 99.5q-8-38-8-74 0-134 94.5-228.5t228.5-94.5q140 0 236 102 109-21 205-78-37 115-142 178 93-10 186-50z"
							/>
						</svg>
					</a>
				</div>
				<div>
					<a href="https://www.linkedin.com/company/kpmg">
						<svg class="h-8 w-8 fill-current hover:text-brand-secondary" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
							<path
								d="M477 625v991h-330v-991h330zm21-306q1 73-50.5 122t-135.5 49h-2q-82 0-132-49t-50-122q0-74 51.5-122.5t134.5-48.5 133 48.5 51 122.5zm1166 729v568h-329v-530q0-105-40.5-164.5t-126.5-59.5q-63 0-105.5 34.5t-63.5 85.5q-11 30-11 81v553h-329q2-399 2-647t-1-296l-1-48h329v144h-2q20-32 41-56t56.5-52 87-43.5 114.5-15.5q171 0 275 113.5t104 332.5z"
							/>
						</svg>
					</a>
				</div>
			</div>
			<div>${FOOTER_LINKS.map((link, i) => html`${i !== 0 ? " | " : ""}<a class="link" href="${getRouteByKey(link.key)}">${link.label}</a>`)}</div>
		</div>
		<div class="text-xs px-2 pb-2">
			&copy; ${moment().year()}
			${i18next.t(
    "KPMG Global Services Hungary Ltd (Business Registration Number: 24350309-2-41), a Hungarian limited liability company, is a member firm of the KPMG network of independent member firms affiliated with KPMG International, a Swiss cooperative. All rights reserved."
  )}
		</div>
		${footerLinksTemplate()}
	</footer>`;
}
