import { useEffect, useState } from "GlobalShared/haunted/CustomHooks";
import i18next from "i18next";
import { html } from "lit-html";
import { currentLocale, getLocalizedUrl } from "../globals";
import { unsafeHTML } from "lit-html/directives/unsafe-html";
import { validatePassword } from "GlobalShared/validators/password-validator";
import { validateEmail } from "GlobalShared/validators/email-validator";
import { footerLinksTemplate, footerTextTemplate } from "GlobalShared/templates/commons";
export const observedAttributes = ["email", "error"];
export const name = "ww-register-by-invitation";
export const Component = (host) => {
  const props = {
    email: host.email,
    error: host.error
  };
  const generateError = () => {
    if (error) {
      switch (error) {
        case "A token must be supplied":
          return i18next.t("Please use the link from the invitation email!");
        case "User already registered":
          return i18next.t("You already have an account. Please sign in");
        case "Token is invalid or expired":
          return i18next.t("Please add the e-mail address where you received the invitation e-mail");
        default:
          return error;
      }
    }
    if (email && !validateEmail(email)) {
      return i18next.t("Please provide a valid e-mail address.");
    }
    if (password && !validatePassword(password)) {
      return i18next.t(
        "Password must be at least 8 digits long and contain any three of the following four qualities: Uppercase Characters, Lowercase Characters, Alphanumeric Characters, Special Characters."
      );
    }
    return void 0;
  };
  const hasError = () => {
    return !email || !password || generateError() !== void 0 ? true : false;
  };
  const [error, setError] = useState(void 0);
  const [email, setEmail] = useState(props.email);
  const [password, setPassword] = useState(void 0);
  useEffect(() => {
    setError(void 0);
  }, [email, password]);
  useEffect(() => {
    setError(props.error);
  }, [props.error]);
  return html` <div class="web-register text-white min-h-screen m-auto p-4 relative sm:pb-20">
		<div class="absolute right-0 mr-4 xl:mr-32"><ww-language-select .onimage=${true}></ww-language-select></div>
		<div class="mt-4 lg:mt-6 flex justify-center">
			<a class="w-auto" href="${getLocalizedUrl("")}" data-routing="server">
				<img src="/images/kpmg_logo_white.png" alt="KPMG logo" />
			</a>
		</div>
			${html` <div class="md:text-xl font-bold py-6 lg:py-12 text-center max-w-screen-lg m-auto text-shadow">
					${unsafeHTML(
    i18next.t(
      "register_welcome_text",
      "Thank you for visiting us. Please note that this is an invite-only platform. If you have not been invited but would like to sign up with KPMG, you can find out more about our offerings for Marketplace Sellers {{- link_start}}here{{- link_end}}.",
      {
        link_start: '<a class="underline" href="https://www.kpmg-vat-compliance.com/" target="_blank">',
        link_end: "</a>"
      }
    )
  )}
				</div>
				<div class="bg-white bg-opacity-75 text-black shadow-md rounded px-8 pb-4 m-auto flex flex-col max-w-sm mb-16">
					<div class="text-xl md:text-3xl font-bold mt-8 mb-6 text-center">${i18next.t("Create your account")}</div>
					<div class="mb-4">
						<dc-input
							.label=${i18next.t("Email")}
							.name=${"Email"}
							.value=${email}
							.placeholder=${i18next.t("Email")}
							.autocomplete=${"off"}
							@change=${(e) => setEmail(e.detail.value)}
						></dc-input>
					</div>
					<div class="mb-4">
						<dc-input
							.label=${i18next.t("Password")}
							.type=${"password"}
							.name=${"Password"}
							.value=${password}
							.placeholder=${"*************"}
							.autocomplete=${"off"}
							@change=${(e) => setPassword(e.detail.value)}
						></dc-input>
					</div>
					<input type="hidden" value=${currentLocale} name=${"locale"} />
					${hasError() ? html`<div class="mb-2">
								<label class="text-red-400"> ${generateError()} </label>
						  </div>` : ""}
					<div class="text-sm mb-4">
						${unsafeHTML(
    i18next.t(
      "login_disclaimer",
      "By signing in or creating an account, you accept our {{- link1_start}}General Terms of Business{{- link1_end}} and {{- link2_start}}Privacy Notice{{- link2_end}}.",
      {
        link1_start: '<a class="underline" href="/files/General_Terms_of_Business.pdf" target="_blank" rel="noopener" download="General_Terms_of_Business.pdf">',
        link1_end: "</a>",
        link2_start: '<a class="underline" href="https://home.kpmg/content/dam/kpmg/hu/pdf/KGSH_Privacy_Statement_v2.pdf" target="_blank" rel="noopener" download="Privacy_Statament.pdf">',
        link2_end: "</a>"
      }
    )
  )}
					</div>
					<div class="m-auto">
						<button class="btn btn-primary" type="submit">${i18next.t("Create Account")}</button>
					</div>
					<div class="mt-4 text-center">
						<span class="text-sm">${i18next.t("You already have an account? Please")}</span>
						<a class="font-semibold underline text-sm" href=${getLocalizedUrl("/account/login/i")} data-routing="server">${i18next.t("sign in")}</a>
					</div>
				</div>`}
			<div class="w-full mt-4 sm:absolute inset-x-0 bottom-0 pb-4 px-2">
                ${footerTextTemplate()}
                ${footerLinksTemplate()}
			</div>
		</div>
	</div>`;
};
